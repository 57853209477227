import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { DataSet } from "../types";
import { Box, useTheme } from "@mui/material";
import { StackAxisLabelsPlugin } from "./Plugins/StackAxesLabelsPlugin";
import { TopLabelsPlugin } from "./Plugins/TopLabelsPlugin";
import { DATALABEL_PROPORTION_DISPLAY_THRESHOLD } from "../../constants";

Chart.register(ChartDataLabels);

type ChartProps = {
  labels: string[];
  datasets: DataSet[];
};

type Props = {
  values?: ChartProps;
  chartType: string;
  legendWidth?: number;
};

export default function BarChart(props: Props) {
  const { values, chartType, legendWidth } = props;
  const { palette } = useTheme();
  const tickCallback = function (value: any, index: number, ticks: any) {
    let label = values?.labels[index];

    // Check if label is too long
    if (label && label.length > 30) {
      // Split the label into words and sequences enclosed in brackets
      let words = label.match(/\S+|\(.*?\)/g) || [];

      // Wrap the words into lines of maximum 20 characters
      let lines: string[] = [];
      let currentLine = words[0] || "";

      for (let i = 1; i < words.length; i++) {
        if ((currentLine + " " + words[i]).length <= 30) {
          currentLine += " " + words[i];
        } else {
          lines.push(currentLine);
          currentLine = words[i] || "";
        }
      }

      lines.push(currentLine);
      return ["", ...lines];
    }

    return label ? ["", label] : null;
  };

  const displayCallback = function (context: Context) {
    let highestValue = 0;
    context.chart?.data?.datasets?.[0]?.data?.forEach(
      (datapoint, index: number) => {
        let sumOriginal = 0;
        let sumAdjusted = 0;

        context.chart.data.datasets.forEach((dataset) => {
          if (dataset.stack === "Original") {
            sumOriginal += dataset.data[index] as number;
          } else {
            sumAdjusted += dataset.data[index] as number;
          }
        });
        highestValue = Math.max(sumOriginal, sumAdjusted, highestValue);
      },
    );

    const percentage =
      Number(context.dataset.data[context.dataIndex]) / highestValue;

    return percentage >= DATALABEL_PROPORTION_DISPLAY_THRESHOLD;
  };

  function getContrastColor(context: any) {
    let hexColor = context.dataset.backgroundColor;
    let hexColourForCDE = palette.activeColor.main;
    if (hexColor == hexColourForCDE) return "black";
    return "white";
  }

  return (
    <>
      {values && (
        <Box
          sx={{
            position: "relative",
            height: "505px",
            width: `calc(100% - ${legendWidth}px - 24px)`,
            display: "flex",
          }}
        >
          <Bar
            data={values}
            plugins={[ChartDataLabels, TopLabelsPlugin, StackAxisLabelsPlugin]}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: displayCallback,
                  color: getContrastColor,
                  anchor: "center",
                  align: "center",
                  formatter: function (value, context) {
                    return value.toFixed(1);
                  },
                },
              },
              maintainAspectRatio: false,
              responsive: true,
              interaction: {
                intersect: false,
              },
              scales: {
                x: {
                  stacked: chartType === "stacked",
                  ticks: {
                    callback: tickCallback,
                  },
                },
                y: {
                  stacked: chartType === "stacked",
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </Box>
      )}
    </>
  );
}
