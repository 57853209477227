import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_Row,
  MRT_Column,
} from "material-react-table";
import { useMemo, useState } from "react";
import { ProductsEditableColumnConfigItem, rowValidationType } from "./types";
import { MicroTableWrapper } from "../../../../components/common";
import {
  COLUMN_WIDTH,
  ROW_ACTION_COLUMN_WIDTH,
  TITLE_COLUMN_WIDTH,
} from "../../constants";
import CustomCellRenderer from "./CustomCellRenderer";
import { validateData } from "./HelperService";
import { AppConstant } from "../../../../constants";
import { UserDataTypes } from "../../../../orval/generated/models";
import ProductsEditableTableRowActions from "./ProductsEditableTableRowActions";
import { useTheme } from "@mui/material";
import { numberParser } from "utils/numberParser";

export type ProductsEditableTableProps = {
  columns: MRT_ColumnDef<any>[];
  data: any;
  onValueUpdate: Function;
  dataStructureKey: string;
  metrics: ProductsEditableColumnConfigItem[];
  setIsEditing?: (value: boolean) => void;
  handleClearAdjustmentsForRow: Function;
  isSubAccordionTable: boolean;
};

export const ProductsEditableTable = ({
  columns,
  data,
  onValueUpdate,
  dataStructureKey,
  metrics,
  setIsEditing,
  handleClearAdjustmentsForRow,
  isSubAccordionTable,
}: ProductsEditableTableProps) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const { palette } = useTheme();

  function handleSave(
    rowId: number,
    updatedValues: Record<string, string>,
    rowType: UserDataTypes,
    validationType: rowValidationType,
  ): void {
    // disabled error handling for table row save
    const newValidationErrors = validateData(
      updatedValues,
      rowType,
      validationType,
    );
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});

    // Remove locale from product values
    const parsedValues = Object.fromEntries(
      Object.entries(updatedValues).map(([key, value]) => {
        return [key, numberParser.parse(value) || AppConstant.emptyCell];
      }),
    );
    onValueUpdate(rowId, parsedValues, rowType);
    table.setEditingRow(null); //exit editing mode
    if (setIsEditing) {
      setIsEditing(false);
    }
  }

  const columns_: any = useMemo(
    () =>
      columns.map((column) => {
        if (column.id === "metric")
          return {
            ...column,
            size: TITLE_COLUMN_WIDTH - ROW_ACTION_COLUMN_WIDTH,
          };
        const columnId = column.id?.toString() ?? "";
        const validationError = validationErrors[columnId];
        return {
          ...column,
          muiEditTextFieldProps: {
            required: true,
            error: !!validationError,
            helperText: validationError,
            placeholder: "",
            onClick: (event: any) => {
              if (event.target.value === AppConstant.emptyCell) {
                event.target.value = "";
              }
            },
            onFocus: () => {
              setValidationErrors((prev) => ({
                ...prev,
                [columnId]: undefined,
              }));
            },
          },
          Cell: ({
            row,
            column,
            renderedCellValue,
          }: {
            row: MRT_Row<any>;
            column: MRT_Column<any, any>;
            renderedCellValue: string;
          }): React.ReactNode => {
            const isAdjustedValuePresent =
              row.original.subRows &&
              row.original.subRows.length > 0 &&
              row.original.subRows[0].type !== null;
            if (isAdjustedValuePresent) {
              return (
                <CustomCellRenderer
                  row={row.original}
                  column={column.columnDef}
                  value={renderedCellValue}
                />
              );
            }
            // renderedCellValue : https://www.material-react-table.com/docs/guides/global-filtering#filter-match-highlighting
            if (renderedCellValue === AppConstant.emptyCell) {
              return renderedCellValue;
            } else {
              if (row.original.type === UserDataTypes.percentage)
                return `${renderedCellValue}%`;
              return renderedCellValue;
            }
          },
        };
      }),
    [columns, data, dataStructureKey, metrics, validationErrors],
  );

  const table = useMaterialReactTable({
    layoutMode: "grid",
    columns: columns_,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false, //hide the bottom toolbar as well if you want\
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row",
    enableColumnPinning: true,
    enableEditing: (row) => (row.subRows?.length ? false : true),
    enableExpanding: true,
    enableColumnResizing: false,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: ROW_ACTION_COLUMN_WIDTH, //adjust the size of the row select column
        grow: false,
        enableResizing: false,
      },
      "mrt-row-expand": {
        size: ROW_ACTION_COLUMN_WIDTH,
        grow: false, //new in v2.8 (allow this column to grow to fill in remaining space)
        enableResizing: false,
      },
    },
    defaultColumn: {
      size: COLUMN_WIDTH, //make columns wider by default
    },
    meta: {
      id: dataStructureKey,
    },
    state: {
      columnOrder: [
        "mrt-row-expand",
        "mrt-row-actions",
        ...columns_.map((column: any) => column.accessorKey),
      ],
    },
    renderRowActions: ({ row, table }) => {
      return (
        <ProductsEditableTableRowActions
          row={row}
          table={table}
          setIsEditing={setIsEditing}
          handleClearAdjustmentsForRow={handleClearAdjustmentsForRow}
        />
      );
    },
    onEditingRowSave: ({ table, values, row }) => {
      const validationType: rowValidationType = {
        metric: row.original.metric,
        type: row.original.validationType,
        isNonZero: row.original.validationisNonZero,
      };

      handleSave(
        metrics[Math.floor(row.id as unknown as number)]?.field_id as number,
        values,
        row.original.type,
        validationType,
      );
    },
    onEditingRowCancel: () => {
      if (setIsEditing) {
        setIsEditing(false);
      }
    },
    enableExpandAll: false,
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor: theme.palette.grey[100],
        width: "100%",
      }),
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        table.setExpanded({ [row.id]: !row.getIsExpanded() });
        if (setIsEditing) {
          setIsEditing(false);
        }
        table.setEditingRow(null);
      },
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
    muiTableBodyCellProps: ({ column, row }) => {
      const iconColumns = ["mrt-row-expand", "mrt-row-actions", "metric"];
      return {
        sx: {
          display: row.subRows?.length
            ? column.id === "mrt-row-actions"
              ? "none"
              : "block"
            : column.id === "mrt-row-expand"
              ? "none"
              : "block",
          justifyContent: iconColumns.includes(column.id)
            ? "flex-start"
            : "center",
          borderLeft:
            iconColumns.includes(column.id) && column.id !== "metric"
              ? "none !important"
              : "",
          borderRight:
            iconColumns.includes(column.id) && column.id !== "metric"
              ? "none !important"
              : "",
          textAlign: "center",
          alignContent: "center",
          "& .css-1s5xrzf .MuiSvgIcon-root": {
            display: row.subRows?.length ? "initial" : "none",
          },
          borderTop: isSubAccordionTable
            ? `1px solid ${palette.textColor.light}`
            : "",
        },
      };
    },
  });

  return (
    <MicroTableWrapper>
      <MaterialReactTable table={table} />
    </MicroTableWrapper>
  );
};
