import { Box, Button, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthSessionStorageKeys } from "../../auth/navigation/config";
import AccessDeniedImage from "../../../assets/images/access_denied_image.svg";
import { useGlobalLoader } from "../../../components/common";
import { useMemberInfo } from "../../../core/MemberInfoProvider/MemberInfoProvider";
import { useEffect } from "react";
import { usePermissions } from "../../../core/navigation/usePermissions";

export function AccessDenied() {
  const { palette } = useTheme();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const token = sessionStorage.getItem(AuthSessionStorageKeys.accessToken);
  const { memberInfoIsFetched } = useMemberInfo();
  const { showGlobalLoader } = useGlobalLoader();
  const { hasFunctionalAccess } = usePermissions();

  const handleBackClick = () => {
    navigate("/home"); // Navigate to the previous page
  };

  useEffect(() => {
    if (memberInfoIsFetched) {
      showGlobalLoader(false);
    }
  }, [memberInfoIsFetched, showGlobalLoader]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifycontent: "center",
          margin: "auto 0",
        }}
      >
        <Typography
          sx={{
            fontSize: "8rem",
            fontWeight: "500",
            color: palette.primary.main,
            height: "8rem",
            textAlign: "right",
          }}
        >
          {t("accessDenied.access")}
        </Typography>
        <Typography
          sx={{
            fontSize: "4rem",
            fontWeight: "500",
            color: palette.primary.main,
            height: "4rem",
            textAlign: "right",
          }}
        >
          {t("accessDenied.denied")}
        </Typography>
        <Typography
          className="header-H2"
          sx={{
            marginTop: "50px",
            textAlign: "right",
            maxWidth: "600px",
          }}
        >
          {t("accessDenied.description")}
        </Typography>
        {token && hasFunctionalAccess && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackClick}
            sx={{ marginTop: "20px", alignSelf: "flex-end" }}
          >
            {t("accessDenied.backButton")}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          height: "100%",
        }}
        height={"100%"}
      >
        <Box
          component="img"
          src={AccessDeniedImage}
          alt="Coming Soon Image"
          style={{
            objectFit: "contain",
            height: "75%",
          }}
        />
      </Box>
    </Box>
  );
}
