import Button from "@mui/material/Button";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ThreeDotMenu from "../../../../components/common/three-dot-menu/ThreeDotMenu";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";
import { useTranslation } from "react-i18next";

type MacroAdjustmentsPageHeaderProps = {
  scenario: ViewMacroScenarioViewModel;
  handleNavigateToResults: () => void;
  headersIndent: string;
};

const MacroAdjustmentsPageHeader = ({
  scenario,
  handleNavigateToResults,
  headersIndent,
}: MacroAdjustmentsPageHeaderProps) => {
  const { t } = useTranslation("macro");
  return (
    <>
      <Button
        variant="text"
        onClick={handleNavigateToResults}
        startIcon={<ArrowBackIosSharpIcon />}
      >
        {t("adjustmentsPage.backToResults")}
      </Button>
      <Box pr={2} pt={1}>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxWidth={"80%"}
          >
            <Box>
              <Typography
                className="header-H2 text-ellipsis"
                sx={{
                  padding: headersIndent,
                  transition: "padding 0.3s ease-in-out",
                }}
              >
                {scenario?.name || t("defaultTitle")}
              </Typography>
            </Box>
          </Box>

          <Box>
            <ThreeDotMenu disabled={true}></ThreeDotMenu>
          </Box>
        </Stack>

        {scenario?.description && (
          <Box width={"90%"} minHeight="45px" mt={1}>
            <Typography
              sx={{
                pointerEvents: "none",
                padding: headersIndent,
                transition: "padding 0.3s ease-in-out",
              }}
              overflow="hidden"
            >
              {scenario?.description}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MacroAdjustmentsPageHeader;
