import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { CustomIcon, Icons, TextEdit } from "../../../../components/common";
import { ProductColumnData } from "../../types";
import ThreeDotMenu from "../../../../components/common/three-dot-menu/ThreeDotMenu";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import { useNavigate } from "react-router-dom";
import { MicroToolRoutesConfig } from "../../navigation/config";
import "./LandingPageHeroSection.scss";
import * as yup from "yup";
import { useFormik } from "formik";

export type LandingPageHeroSectionProps = {
  title: string;
  onRenameScenario: Function;
  onExportResults: Function;
  onSelectProduct: Function;
  handleRenameDescription: Function;
  isEditScenario?: boolean;
  description: string;
  handleClearProducts: Function;
  handleClearAdjustments: Function;
  products: ProductColumnData[] | undefined;
  skusIsPending: boolean;
  hasAdjustments: boolean
};

function LandingPageHeroSection({
  title,
  onRenameScenario,
  onExportResults,
  onSelectProduct,
  isEditScenario,
  description,
  handleRenameDescription,
  handleClearProducts,
  handleClearAdjustments,
  products,
  skusIsPending,
  hasAdjustments,
}: LandingPageHeroSectionProps) {
  const descriptionValidationSchema = yup.object({
    scenarioDescription: yup
      .string()
      .max(255, "Description must be at most 255 characters"),
  });

  const { t } = useTranslation(["micro", "common"]);
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState<boolean>(false);

  const formikDesc = useFormik<{ scenarioDescription: string }>({
    initialValues: {
      scenarioDescription: description ?? "",
    },
    enableReinitialize: true,
    validationSchema: descriptionValidationSchema,
    onSubmit: ({ scenarioDescription }) => {
      if (description !== scenarioDescription) {
        handleRenameDescription(scenarioDescription);
      }
      setEditDescription(false);
    },
  });

  const descriptionPlaceholder =
    formikDesc.values.scenarioDescription && formikDesc.values.scenarioDescription.length > 0
      ? formikDesc.values.scenarioDescription
      : t("noDescription");

  const navigate = useNavigate();
  const redirectURL = MicroToolRoutesConfig.productLevelAnalytics;

  const handleRenameScenario = () => {
    setEditingName(true);
  };

  const handleInputSave = (value: string) => {
    onRenameScenario(value);
    setEditingName(false);
  };

  return (
    <Box maxWidth={`100%`} sx={{ backgroundColor: "white" }}>
      <Button
        variant="text"
        onClick={() => navigate(`${redirectURL}?`)}
        startIcon={<ArrowBackIosSharpIcon />}
      >
        {t("backToMicroHome")}
      </Button>
      <Box display={"flex"} height={"55px"} width="100%">
        <Box display="flex" width={"80%"}>
          {!editingName ? (
            <Box
              onClick={handleRenameScenario}
              maxWidth={"100%"}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleRenameScenario();
                }
              }}
              tabIndex={0}
            >
              <Typography className="header-H2 text-ellipsis micro-header">
                {title}
              </Typography>
            </Box>
          ) : (
            <>
              <TextEdit
                testId="name"
                value={title}
                onSaveInput={handleInputSave}
              ></TextEdit>
            </>
          )}
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          maxWidth={"20%"}
          marginLeft={"auto"}
          minWidth="14em"
        >
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={() => {
              onSelectProduct();
            }}
            startIcon={
              <CustomIcon
                name={Icons.ADD}
                width={"24px"}
                height={"24px"}
                fill="white"
              />
            }
            disabled={skusIsPending}
          >
            {t("micro:heroSectionActions.selectProduct")}
            {skusIsPending && (
              <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
            )}
          </Button>
          <ThreeDotMenu
            menuItems={[
              {
                label: t("heroSectionActions.exportResults"),
                onClick: () => onExportResults(),
                disabled: !products?.length,
              },
              {
                label: t("heroSectionActions.clearSelection"),
                onClick: () => handleClearProducts(products ?? []),
                disabled: !products?.length,
              },
              {
                label: t("heroSectionActions.clearAdjustments"),
                onClick: () => handleClearAdjustments(),
                disabled: !hasAdjustments,
              },
            ]}
          ></ThreeDotMenu>
        </Box>
      </Box>
      <Box mt={1} width={"95%"} minHeight="45px">
        {editDescription ? (
          <TextField
            sx={{ width: "100%" }}
            placeholder={t("noDescription")}
            variant="outlined"
            data-testid="description"
            name="scenarioDescription"
            value={formikDesc.values.scenarioDescription}
            hiddenLabel
            multiline
            onChange={formikDesc.handleChange}
            onBlur={(() => formikDesc.handleSubmit())}
            autoFocus
            focused
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
                formikDesc.handleSubmit()
              }
            }}
            error={formikDesc.touched.scenarioDescription && Boolean(formikDesc.errors.scenarioDescription)}
            helperText={formikDesc.touched.scenarioDescription && formikDesc.errors.scenarioDescription as string}
          />
        ) : (
          <Typography
            sx={
              isEditScenario
                ? { cursor: "pointer", padding: "14px" }
                : { pointerEvents: "none", padding: "14px" }
            }
            className="description-hover"
            onClick={() => setEditDescription(true)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setEditDescription(true);
              }
            }}
            noWrap
            tabIndex={0}
          >
            {descriptionPlaceholder}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default LandingPageHeroSection;
