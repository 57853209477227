import { Chart } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { ProductConfigurationsMicro } from "../../ProductsEditableTable";

export const TopLabelsPlugin = {
  id: "topLabels",
  afterDatasetsDraw(chart: Chart, args: Object, pluginOptions: Options) {
    const { ctx } = chart;

    // Pull out the original and adjusted data points for summing
    chart?.data?.datasets?.[0]?.data?.forEach((datapoint, index: number) => {
      const datasetOriginalArray: number[] = [];
      const datasetAdjustedArray: number[] = [];

      chart.data.datasets.forEach((dataset) => {
        dataset.stack === "Original"
          ? datasetOriginalArray.push(dataset.data[index] as number)
          : datasetAdjustedArray.push(dataset.data[index] as number);
      });

      const totalSum = (total: number, values: number) => total + values;

      // Total Values to display
      let sumOriginal = formatNumber(
        datasetOriginalArray.reduce(totalSum, 0),
        ProductConfigurationsMicro.RESULTS,
      );
      let sumAdjusted = formatNumber(
        datasetAdjustedArray.reduce(totalSum, 0),
        ProductConfigurationsMicro.RESULTS,
      );

      ctx.font = "bold 12px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";

      // .findLastIndex not yet supported to find x,y of top stacked section (usually CDE)
      if (datasetOriginalArray.length !== 0) {
        const lastOriginalDataPointIndex =
          chart.data.datasets.length -
          1 -
          [...chart.data.datasets]
            .reverse()
            .findIndex((x) => x.stack === "Original");

        ctx.fillText(
          sumOriginal,
          chart.getDatasetMeta(lastOriginalDataPointIndex).data[index].x,
          chart.getDatasetMeta(lastOriginalDataPointIndex).data[index].y - 5,
        );
      }
      if (datasetAdjustedArray.length !== 0) {
        const lastAdjustedDataPointIndex =
          chart.data.datasets.length -
          1 -
          [...chart.data.datasets]
            .reverse()
            .findIndex((x) => x.stack === "Adjusted");
        ctx.fillText(
          sumAdjusted,
          chart.getDatasetMeta(lastAdjustedDataPointIndex).data[index].x,
          chart.getDatasetMeta(lastAdjustedDataPointIndex).data[index].y - 5,
        );
      }
    });
  },
};
