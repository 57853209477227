import axios from "axios";
import { getBaseURL } from "../../../core/http/http-utils";
import { EnvironmentVariable } from "../../../constants/EnvironmentVariables";
import { AuthSessionStorageKeys } from "../navigation/config";
import { decodeJWTToken } from "../../../core/http/session-service";
import { JwtPayload } from "jwt-decode";

const { idamConfig } = EnvironmentVariable;

interface AuthObject {
  access_token: string;
  refresh_token: string;
  id_token: string;
  scope: "openid";
  token_type: "Bearer";
  expires_in: number;
}
export async function getIDAMAccessToken(code: string) {
  try {
    const baseURL = `${getBaseURL(idamConfig.idamAPIURL)}access_token`;
    const clientId = getBaseURL(idamConfig.clientId);
    const clientSecret = getBaseURL(idamConfig.clientSecret);
    const redirectURL = `${getBaseURL(idamConfig.appURL)}/auth`;
    const params = new URLSearchParams();
    params.append("client_id", clientId ?? "");
    params.append("client_secret", clientSecret ?? "");
    params.append("redirect_uri", redirectURL ?? "");
    params.append("code", code);
    params.append("grant_type", "authorization_code");
    const response = await axios.post(baseURL, params);
    return response.data;
  } catch (err) {
    console.error("Unable to get a token.");
    return;
  }
}

export async function revokeTokenAndEndSession() {
  try {
    const baseURL = `${getBaseURL(idamConfig.idamAPIURL)}token/revoke`;
    const idToken =
      sessionStorage.getItem(AuthSessionStorageKeys.idToken) || "";
    const accessToken = sessionStorage.getItem(
      AuthSessionStorageKeys.accessToken,
    );
    const clientId = getBaseURL(idamConfig.clientId);
    const clientSecret = getBaseURL(idamConfig.clientSecret);
    const params = new URLSearchParams();
    params.append("client_id", clientId ?? "");
    params.append("client_secret", clientSecret ?? "");
    params.append("token", accessToken ?? "");
    const response = await axios.post(baseURL, params);
    if (response?.status === 200) {
      clearAuthDataFromSessionStorage();
      initiateLogout(idToken);
      return true;
    }
  } catch (err) {
    console.error("Unable to revoke token.");
    return;
  }
}

export function isTokenExpired(idToken: string): boolean {
  const decodedToken: JwtPayload = decodeJWTToken(idToken);
  const currentTime = Math.floor(Date.now() / 1000);

  if (decodedToken && decodedToken.exp) {
    return decodedToken.exp <= currentTime;
  }

  return true;
}

function initiateLogout(idToken: string): void {
  const redirect_uri = `${getBaseURL(idamConfig.appURL)}/auth/logout`;

  if (isTokenExpired(idToken)) {
    window.location.replace(redirect_uri);
    return;
  }

  const endSessionAndRedirectURL = `${getBaseURL(idamConfig.idamLogoutURL)}id_token_hint=${idToken}&post_logout_redirect_uri=${redirect_uri}`;

  window.location.replace(endSessionAndRedirectURL);
  return;
}

export function clearAuthDataFromSessionStorage(): void {
  sessionStorage.removeItem(AuthSessionStorageKeys.accessToken);
  sessionStorage.removeItem(AuthSessionStorageKeys.userInfo);
  sessionStorage.removeItem(AuthSessionStorageKeys.idToken);
  sessionStorage.removeItem(AuthSessionStorageKeys.refreshToken);
}

export function handleAuthObject(authObject: AuthObject): void {
  if (authObject) {
    clearAuthDataFromSessionStorage();
    sessionStorage.setItem(
      AuthSessionStorageKeys.accessToken,
      authObject.access_token,
    );
    sessionStorage.setItem(AuthSessionStorageKeys.idToken, authObject.id_token);
    sessionStorage.setItem(
      AuthSessionStorageKeys.refreshToken,
      authObject.refresh_token,
    );
    const userInfo = decodeJWTToken(authObject.id_token);
    if (userInfo) {
      sessionStorage.setItem(
        AuthSessionStorageKeys.userInfo,
        JSON.stringify(userInfo),
      );
    }
  }
}
