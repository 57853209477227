import {
  CreateMicroAdjustment,
  EditMicroAdjustment,
  IngredientViewModel,
  MaterialViewModel,
  OtherPackagingSubAccordionEnum,
  Product,
  SKUAdjustmentAccordionDetails,
  SKUField,
  UserDataTypes,
} from "../../../../orval/generated/models";
import { ProductColumnData } from "../../types";
import { useTranslation } from "react-i18next";
import {
  useGetIngredientsFieldsForIngredientsAccordionIngredientsGet,
  useGetIngredientsFieldsForIngredientsAccordionIngredientsEmissionFactorsGet,
  useGetMaterialsByPartTypeMaterialsPartTypeGet,
  useGetMaterialsEfByPartTypeMaterialsPartTypeEmissionFactorsGet,
  useCreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost,
  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey,
  useEditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch,
  useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete,
} from "../../../../orval/generated/endpoint";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  getProductsEditableTableData,
  getTableColumns,
  ProductConfigurationsMicro,
  ProductsEditableTable,
} from "../ProductsEditableTable";
import { generateTableMetric } from "./generateMetrics";
import generateConfig from "./generateConfig";
import { Box, Typography } from "@mui/material";
import CustomAccordion from "./CustomAccordion";
import SelectDropdown from "../../../../components/common/select-dropdown/SelectDropdown";
import { useSnackbar } from "../../../../components/common/notification/showSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import { useGlobalLoader } from "components/common";
import { deepClone } from "utils";
import { AppConstant } from "../../../../constants";

type IngredientOrMaterial = {
  field_id: number,
  ingredient?: string,
  material?: string,
}

const RenderAccordions = (
  accordionDetails: SKUAdjustmentAccordionDetails,
  productColumnData: ProductColumnData[] | undefined,
  products: Product[],
  isOtherPackagingEmissionFactors?: Boolean,
  parentAccordion?: string,
  isSubAccordion?: boolean,
) => {
  const { t } = useTranslation(["micro", "common"]);
  const showSnackbar = useSnackbar();
  const { showGlobalLoader } = useGlobalLoader();
  const queryClient = useQueryClient();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const getInitialScenarioId = () => {
    return urlScenarioId || location.state?.id;
  };

  const getOtherPackagingSubAccordionEnum = (sub_accordion: string) => {
    switch (sub_accordion) {
      case ProductConfigurationsMicro.OTHER_PACKAGING_PRIMARY:
        return OtherPackagingSubAccordionEnum.Other_primary;
      case ProductConfigurationsMicro.OTHER_PACKAGING_SECONDARY:
        return OtherPackagingSubAccordionEnum.Secondary;
      default:
        return OtherPackagingSubAccordionEnum.Tertiary;
    }
  };

  const allowedMaterialQueryStrings = [
    ProductConfigurationsMicro.OTHER_PACKAGING_PRIMARY,
    ProductConfigurationsMicro.OTHER_PACKAGING_SECONDARY,
    ProductConfigurationsMicro.OTHER_PACKAGING_TERTIARY,
  ];

  const { data: materialsData, error: errorFetchingMaterialsData } =
    useGetMaterialsByPartTypeMaterialsPartTypeGet(
      getOtherPackagingSubAccordionEnum(accordionDetails.accordion),
      {
        query: {
          enabled: allowedMaterialQueryStrings.includes(
            accordionDetails.accordion as ProductConfigurationsMicro,
          ),
        },
      },
    );

  const { data: materialsEFData, error: errorFetchingmaterialsEFData } =
    useGetMaterialsEfByPartTypeMaterialsPartTypeEmissionFactorsGet(
      getOtherPackagingSubAccordionEnum(accordionDetails.accordion),
      {
        query: {
          enabled: allowedMaterialQueryStrings.includes(
            accordionDetails.accordion as ProductConfigurationsMicro,
          ),
        },
      },
    );

  const { data: ingredientsData, error: errorFetchingIngredientsData } =
    useGetIngredientsFieldsForIngredientsAccordionIngredientsGet({
      query: {
        enabled:
          accordionDetails.accordion === ProductConfigurationsMicro.INGREDIENTS,
      },
    });

  const {
    data: ingredientsEmissionData,
    error: errorFetchingingredientsEmissionData,
  } =
    useGetIngredientsFieldsForIngredientsAccordionIngredientsEmissionFactorsGet(
      {
        query: {
          enabled:
            accordionDetails.accordion ===
            ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS,
        },
      },
    );

  const {
    mutateAsync: createMicroAdjustments,
    isPending: createMicroAdjustmentsIsPending,
  } = useCreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            getInitialScenarioId(),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: editMicroAdjustments,
    isPending: editMicroAdjustmentsIsPending,
  } = useEditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            getInitialScenarioId(),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: deleteMicroAdjustments,
    isPending: deleteMicroAdjustmentIsPendings,
  } =
    useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete(
      {
        mutation: {
          onSuccess: (data: unknown) => {
            queryClient.invalidateQueries({
              queryKey:
                getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                  getInitialScenarioId(),
                ),
            });
            showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
          },
          onError: (error: any) => {
            showSnackbar(t("errorMessages.errorUpdating"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const getItemsList = useCallback(
    (metric: string, useTranslation?: boolean) => {
      switch (metric) {
        case ProductConfigurationsMicro.INGREDIENTS:
          return (
            ingredientsData?.map(
              (ingredient: IngredientViewModel) => ingredient.ingredient,
            ) || []
          );
        case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
          return (
            ingredientsEmissionData?.map(
              (ingredient: IngredientViewModel) => ingredient.ingredient,
            ) || []
          );

        default:
          if (isOtherPackagingEmissionFactors) {
            return (
              materialsEFData?.map(
                (material: MaterialViewModel) => material.material,
              ) || []
            );
          } else {
            return (
              materialsData?.map(
                (material: MaterialViewModel) => material.material,
              ) || []
            );
          }
      }
    },
    [
      ingredientsData,
      ingredientsEmissionData,
      isOtherPackagingEmissionFactors,
      materialsData,
      materialsEFData,
    ],
  );

  const getItemsListWithFieldIds = useCallback<
    (metric: string) => IngredientViewModel[] | MaterialViewModel[]
  >(
    (metric: string) => {
      switch (metric) {
        case ProductConfigurationsMicro.INGREDIENTS:
          return ingredientsData || [];
        case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
          return ingredientsEmissionData || [];

        default:
          if (isOtherPackagingEmissionFactors) {
            return materialsEFData || [];
          } else {
            return materialsData || [];
          }
      }
    },
    [
      ingredientsData,
      ingredientsEmissionData,
      isOtherPackagingEmissionFactors,
      materialsData,
      materialsEFData,
    ],
  );

  //This function returns all adjustments for a single product, including the ones inside subAccordions
  const getFlattenedAdjustments = useCallback(
    (product_guid: string) => {
      const adjustments = products.find(
        (pro) => pro.guid === product_guid,
      )?.adjustments;
      let flattenedAdjustment = adjustments ? [...adjustments] : [];
      adjustments?.forEach((adj) => {
        if (adj.sub_accordions?.length) {
          flattenedAdjustment = [...flattenedAdjustment, ...adj.sub_accordions];
        }
      });
      return flattenedAdjustment;
    },
    [products],
  );

  const adjustedFields = useMemo(() => {
    const itemsList = getItemsList(accordionDetails.accordion);
    const itemsObjectList = getItemsListWithFieldIds(
      accordionDetails.accordion,
    );

    return itemsList?.filter((item: string) => {
      if (!products) {
        return false;
      }

      let itemObject: IngredientViewModel | MaterialViewModel = {
        field_id: 0,
        ingredient: "ingredient",
      };

      switch (accordionDetails.accordion) {
        case ProductConfigurationsMicro.INGREDIENTS:
        case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
          itemObject = (itemsObjectList as IngredientViewModel[]).find(
            (obj) => obj.ingredient === item,
          ) || { field_id: 0, ingredient: "ingredient" };
          break;
        default:
          itemObject = (itemsObjectList as MaterialViewModel[]).find(
            (obj) => obj.material === item,
          ) || { field_id: 0, material: "material" };
          break;
      }

      // ? return true if any product for this has been adjusted
      return products.some((product) => {
        const adjustments = getFlattenedAdjustments(product.guid).filter(
          (adj) => adj.accordion === accordionDetails.accordion,
        );

        let fields: SKUField[] = [];

        adjustments.forEach((adj) => {
          fields = [...fields, ...(adj.fields as SKUField[])];
        });

        let adjustment = fields.find(
          (field: SKUField) => field.field_id === itemObject.field_id,
        )?.adjustment_id;

        return adjustment && true;
      });
    });
  }, [
    accordionDetails.accordion,
    getFlattenedAdjustments,
    getItemsList,
    getItemsListWithFieldIds,
    products,
  ]);

  const [selected, setSelected] = useState<string[] | undefined>(
    adjustedFields,
  );

  useEffect(() => {
    if (adjustedFields?.length) {
      setSelected(adjustedFields);
    }
  }, [adjustedFields]);

  const selectedMetrics = useMemo(
    () =>
      generateTableMetric(
        accordionDetails,
        selected,
        getItemsListWithFieldIds(accordionDetails.accordion),
        t,
      ),
    [accordionDetails, getItemsListWithFieldIds, selected, t],
  );

  const columns = getTableColumns(
    productColumnData as ProductColumnData[],
    selectedMetrics,
    340,
    true,
    accordionDetails.accordion !== ProductConfigurationsMicro.SERVING_SIZE,
    isEditing,
  );

  const tableData = useMemo(
    () =>
      getProductsEditableTableData(
        accordionDetails.accordion,
        selectedMetrics,
        productColumnData,
        products,
        generateConfig(
          accordionDetails,
          ingredientsData,
          ingredientsEmissionData,
          isOtherPackagingEmissionFactors ? materialsEFData : materialsData,
          t
        ),
        false,
        parentAccordion,
      ),
    [accordionDetails, selectedMetrics, productColumnData, products, ingredientsData, ingredientsEmissionData, isOtherPackagingEmissionFactors, materialsEFData, materialsData, t, parentAccordion],
  );

  useEffect(() => {
    if (
      errorFetchingIngredientsData ||
      errorFetchingingredientsEmissionData ||
      errorFetchingMaterialsData ||
      errorFetchingmaterialsEFData
    ) {
      showSnackbar(
        t("micro:errorMessages.errorFetchingMaterialsOrIngredients"),
        "error",
      );
    }
  }, [
    errorFetchingIngredientsData,
    errorFetchingMaterialsData,
    errorFetchingingredientsEmissionData,
    errorFetchingmaterialsEFData,
    showSnackbar,
    t,
  ]);

  useEffect(() => {
    createMicroAdjustmentsIsPending ||
      editMicroAdjustmentsIsPending ||
      deleteMicroAdjustmentIsPendings
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [showGlobalLoader, createMicroAdjustmentsIsPending, editMicroAdjustmentsIsPending, deleteMicroAdjustmentIsPendings]);

  const handleSave = async (
    field_id: number,
    updatedValues: any,
    rowType: UserDataTypes,
  ) => {
    const _updated_values = deepClone(updatedValues);
    delete _updated_values.metric; //TODO: find a way to get rid of this key entirly

    const newAdjustments: CreateMicroAdjustment[] = [];
    const updatedAdjustments: EditMicroAdjustment[] = [];
    const deletedAdjustments: string[] = [];

    Object.keys(_updated_values).forEach((product_guid) => {
      const cleanedProduct_guid = product_guid.replace("Product ", "");
      let flattenedAdjustment = getFlattenedAdjustments(cleanedProduct_guid);
      //beacuse flattened adjustments array may contain two records with the same accordion name
      const adjustment = flattenedAdjustment.filter(
        (adj) => adj.accordion === accordionDetails.accordion,
      );
      let fields: SKUField[] = [];

      adjustment.forEach((adj) => {
        fields = [...fields, ...(adj.fields as SKUField[])];
      });

      let field = fields.find((f) => f.field_id === field_id);

      if (field) {
        if (!field.adjustment_id) {
          if (_updated_values[product_guid] !== AppConstant.emptyCell) {
            const newAdjustment = {
              field_id,
              product_guid: cleanedProduct_guid,
              action: rowType,
              adjusted_value: Number(_updated_values[product_guid]),
            };

            newAdjustments.push(newAdjustment);
          }
        } else {
          if (_updated_values[product_guid] !== AppConstant.emptyCell) {
            const updatedAdjustment = {
              guid: field.adjustment_id,
              action: rowType,
              adjusted_value: Number(_updated_values[product_guid]),
            };

            updatedAdjustments.push(updatedAdjustment);
          } else {
            deletedAdjustments.push(field.adjustment_id)
          }
        }
      }
    });

    if (newAdjustments.length) {
      try {
        await createMicroAdjustments({
          scenarioId: getInitialScenarioId(),
          data: newAdjustments,
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (deletedAdjustments.length) {
      try {
        await deleteMicroAdjustments({ scenarioId: getInitialScenarioId(), data: { adjustment_guids: deletedAdjustments } });
      } catch (e) {
        console.log(e);
      }
    }

    if (updatedAdjustments.length) {
      try {
        await editMicroAdjustments({
          scenarioId: getInitialScenarioId(),
          data: updatedAdjustments,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleClearAdjustmentsForRow = async (
    adjustmentsIds: string[] | null,
  ) => {
    if (adjustmentsIds) {
      try {
        await deleteMicroAdjustments({ scenarioId: getInitialScenarioId(), data: { adjustment_guids: adjustmentsIds } });
      } catch (e) {
        console.log(e);
      }
    } else {
      showSnackbar(t("errorMessages.errorUpdating"), "error");
    }
  };

  const handleRemoveItem = async (removedItems: any[]) => {
    let list: IngredientOrMaterial[] = []
    let removedAdjustments: SKUField[] = []
    let accessor: keyof IngredientOrMaterial = "ingredient"

    switch (accordionDetails.accordion) {
      case ProductConfigurationsMicro.INGREDIENTS:
        list = getItemsListWithFieldIds(ProductConfigurationsMicro.INGREDIENTS) as IngredientOrMaterial[]
        accessor = "ingredient"
        break;
      case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
        list = getItemsListWithFieldIds(ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS) as IngredientOrMaterial[]
        break;

      default:
        list = getItemsListWithFieldIds(ProductConfigurationsMicro.OTHER_PACKAGING) as IngredientOrMaterial[]
        accessor = "material"
        break;
    }

    removedItems.forEach(item => {
      const removedItem = (list as IngredientOrMaterial[]).find(i => i[accessor] === item)

      products.forEach(product => {
        const flattenedAdjustments = getFlattenedAdjustments(product.guid)
        flattenedAdjustments.forEach(adj => {
          adj.fields?.forEach(field => {
            if ((field.field_id === removedItem?.field_id) && field.adjustment_id) removedAdjustments.push(field)
          })
        })
      })
    })

    if (removedAdjustments.length) {
      try {
        await deleteMicroAdjustments({ scenarioId: getInitialScenarioId(), data: { adjustment_guids: removedAdjustments.map(adj => adj.adjustment_id as string) } })
      } catch (e) {
        console.log(e)
      }
    }
  }

  switch (accordionDetails.accordion) {
    case ProductConfigurationsMicro.SERVING_SIZE:
    case ProductConfigurationsMicro.MANUFACTURING:
    case ProductConfigurationsMicro.LOGISTICS:
    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
      return (
        <>
          <ProductsEditableTable
            columns={columns}
            data={tableData}
            onValueUpdate={handleSave}
            dataStructureKey={accordionDetails.accordion}
            metrics={selectedMetrics}
            setIsEditing={setIsEditing}
            handleClearAdjustmentsForRow={handleClearAdjustmentsForRow}
            isSubAccordionTable={!!isSubAccordion}
          ></ProductsEditableTable>
        </>
      );

    default:
      if (
        accordionDetails.sub_accordions &&
        accordionDetails.sub_accordions.length
      )
        return (
          <>
            {accordionDetails.sub_accordions.map(
              (sub_accordion: SKUAdjustmentAccordionDetails): ReactNode =>
                products && (
                  <Box key={sub_accordion.accordion}>
                    <CustomAccordion
                      title={sub_accordion.accordion}
                      details={RenderAccordions(
                        sub_accordion,
                        productColumnData,
                        products,
                        isOtherPackagingEmissionFactors,
                        accordionDetails.accordion,
                        true,
                      )}
                      accordion={sub_accordion}
                    />
                  </Box>
                ),
            )}
          </>
        );

      return (
        <Box sx={{ display: "flex", flexDirection: "column" }} mt={2}>
          <Box sx={{ maxWidth: "15em" }}>
            <SelectDropdown
              listItems={getItemsList(accordionDetails.accordion) || []}
              onSave={setSelected}
              savedSelectedItems={selected || []}
              title={t("ingredientsSection.buttonTitle")}
              onRemove={handleRemoveItem}
              selectAll={true}
              translate={allowedMaterialQueryStrings.includes(
                accordionDetails.accordion as ProductConfigurationsMicro,
              )}
            ></SelectDropdown>
          </Box>

          {products?.length &&
            tableData?.length &&
            columns?.length &&
            selected?.length ? (
            <Box mt={1}>
              <ProductsEditableTable
                columns={columns}
                data={tableData}
                onValueUpdate={handleSave}
                dataStructureKey={accordionDetails.accordion}
                metrics={selectedMetrics}
                setIsEditing={setIsEditing}
                handleClearAdjustmentsForRow={handleClearAdjustmentsForRow}
                isSubAccordionTable={!!isSubAccordion}
              ></ProductsEditableTable>
            </Box>
          ) : (
            <Box
              sx={{
                border: "1px dashed #cbcbcb",
                padding: "40px",
                textAlign: "center",
                maxWidth: "95vw",
                marginTop: 2,
              }}
            >
              <Typography>{t("adjustmentsSection.noAdjustments")}</Typography>
            </Box>
          )}
        </Box>
      );
  }
};

export default RenderAccordions;
