import { useTranslation } from "react-i18next";
import { Typography, Box, Button, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { CustomIcon, Icons } from "../../../components/common/custom-icon";
import ScenarioSummary from "./ScenarioSummary";
import { Loader } from "../../../components/common";
import {
  UpdateMacroScenarioViewModel,
  ViewMacroScenarioViewModel,
} from "../../../orval/generated/models";
import { UseMutateAsyncFunction } from "@tanstack/react-query";

type Props = {
  scenario: ViewMacroScenarioViewModel | null;
  setAdjustmentsList: UseMutateAsyncFunction<
    ViewMacroScenarioViewModel,
    any,
    { scenarioId: number; data: UpdateMacroScenarioViewModel },
    unknown
  >;
  setNewAdjustment: Dispatch<SetStateAction<boolean>>;
  adjustmentsList: ViewMacroScenarioViewModel | null;
  handleEdit: (title: string, product: any) => void; // todo replace any (used to be Adjustment)
  handleDeleteAdjustment?: Function;
};

function AdjustmentsList({
  scenario,
  setAdjustmentsList,
  setNewAdjustment,
  handleEdit,
  handleDeleteAdjustment,
}: Props) {
  const { t } = useTranslation("macro");
  const { palette } = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleDelete(key: string) {
    setIsLoading(true);
    if (handleDeleteAdjustment) {
      handleDeleteAdjustment(key);
      setIsLoading(false);
    }
  }
  // TODO: business would like adjustments disabled for now
  return null;
  // return (
  //   <Box>
  //     <Typography
  //       sx={{ display: "inline-block", margin: "24px 0" }}
  //       className="header-H4"
  //     >
  //       {t("allAdjustments")}
  //     </Typography>
  //
  //     {isLoading ? (
  //       <Loader />
  //     ) : (
  //       <Box display="flex" gap="12px" flexDirection="column">
  //         {scenario?.inputs?.adjustments ? (
  //           scenario?.inputs?.adjustments?.map(
  //             (adjustment: Adjustment, idx: number) => (
  //               <Box
  //                 key={idx}
  //                 sx={{
  //                   border: `1px solid ${palette.textColor.light}`,
  //                   borderRadius: "5px",
  //                 }}
  //               >
  //                 <ScenarioSummary
  //                   key={idx}
  //                   side={true}
  //                   data={adjustment}
  //                   title={adjustment.adjustment_name}
  //                   handleDelete={handleDelete}
  //                   handleEdit={handleEdit}
  //                 />
  //               </Box>
  //             ),
  //           )
  //         ) : (
  //           <Typography textAlign="center" m={5} variant="h6" color={"#bdbdbd"}>
  //             {t("noAdjustmentsMessage")}
  //           </Typography>
  //         )}
  //       </Box>
  //     )}
  //
  //     <Box display="flex" justifyContent="end" sx={{ margin: "24px 0" }}>
  //       <Button
  //         disabled={isLoading}
  //         variant="contained"
  //         color="primary"
  //         startIcon={
  //           <CustomIcon
  //             name={Icons.ADD}
  //             width={"24px"}
  //             height={"24px"}
  //             fill="white"
  //           />
  //         }
  //         onClick={() => setNewAdjustment(true)}
  //       >
  //         {t("buttonLabel.addAdjustments")}
  //       </Button>
  //     </Box>
  //   </Box>
  // );
}

export default AdjustmentsList;
