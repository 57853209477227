import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { TextEdit } from "../../../../components/common";
import { TextField } from "@mui/material";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";
import * as yup from "yup";
import { useFormik } from "formik";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { MacroToolRoutesConfig } from "../../navigation/config";
import { useMemberInfo } from "../../../../core/MemberInfoProvider/MemberInfoProvider";

export type MacroPageHeroSectionProps = {
  onRenameScenario?: (value: string) => void;
  scenario: ViewMacroScenarioViewModel | null;
  isEditScenario?: boolean;
  handleRenameDescription: Function;
  headersIndent: string;
};

export function MacroPageHeroSection({
  onRenameScenario,
  scenario,
  isEditScenario,
  handleRenameDescription,
  headersIndent,
}: MacroPageHeroSectionProps) {
  const { t } = useTranslation("macro");
  const navigate = useNavigate();
  const [editName, setEditName] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const descriptionValidationSchema = yup.object({
    description: yup
      .string()
      .max(255, "Description must be at most 255 characters"),
  });

  //
  // IMPORTANT - TO BE REMOVED WHEN WE RELEASE MACRO ADJUSTMENTS
  const { memberInfo } = useMemberInfo();
  const isPwCUser = memberInfo?.email?.includes("@pwc.com");
  // IMPORTANT - TO BE REMOVED WHEN WE RELEASE MACRO ADJUSTMENTS
  //

  const formikDesc = useFormik<{ description: string }>({
    initialValues: {
      description: scenario?.description ?? "",
    },
    validationSchema: descriptionValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (scenario?.description !== values.description) {
        handleRenameDescription(values.description);
      }
      setEditDescription(false);
    },
  });

  const descriptionPlaceholder =
    formikDesc.values.description && formikDesc.values.description.length > 0
      ? formikDesc.values.description
      : t("noDescription");

  const handleRenameScenario = () => {
    setEditName(true);
  };

  const handleInputSave = (value: string) => {
    if (onRenameScenario) {
      onRenameScenario(value);
      setEditName(false);
    }
  };

  const handleNavigateToAdjustmentsPage = () => {
    if (scenario?.id) {
      navigate(
        MacroToolRoutesConfig.adjustmentsPage.replace(
          ":id",
          String(scenario.id),
        ),
        {
          state: {
            id: scenario.id,
          },
        },
      );
    }
  };

  const notAdjustable =
    scenario?.inputs?.countries?.length === 0 ||
    scenario?.inputs?.years?.base_year === null ||
    scenario?.inputs?.years?.target_year === null;

  return (
    <Box pr={2} pt={1}>
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        justifyContent={{ md: "space-between" }}
        alignItems={{ xs: "flex-start", md: "center" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxWidth={"80%"}
        >
          {!editName ? (
            <Box onClick={handleRenameScenario}>
              <Typography
                className="header-H2 text-ellipsis"
                sx={{
                  padding: headersIndent,
                  transition: "padding 0.3s ease-in-out",
                }}
              >
                {scenario?.name || t("defaultTitle")}
              </Typography>
            </Box>
          ) : (
            <>
              <TextEdit
                value={scenario?.name || ""}
                onSaveInput={handleInputSave}
              ></TextEdit>
            </>
          )}
        </Box>

        <Box>
          {isPwCUser && (
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleNavigateToAdjustmentsPage}
              endIcon={<ArrowForwardIcon />}
              disabled={editName || editDescription || notAdjustable}
            >
              {t("buttonLabel.viewAdjustments")}
            </Button>
          )}
        </Box>
      </Stack>

      <Box width={editDescription ? "100%" : "90%"} minHeight="45px" mt={1}>
        {editDescription ? (
          <TextField
            sx={{ width: "100%" }}
            placeholder={t("noDescription")}
            variant="outlined"
            value={formikDesc.values.description}
            multiline
            hiddenLabel
            name="description"
            onChange={formikDesc.handleChange}
            onBlur={() => formikDesc.handleSubmit()}
            autoFocus
            focused
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
                formikDesc.handleSubmit();
              }
            }}
            data-testid="text-edit-scenario-description"
            error={
              formikDesc.touched.description &&
              Boolean(formikDesc.errors.description)
            }
            helperText={
              formikDesc.touched.description &&
              (formikDesc.errors.description as string)
            }
          />
        ) : (
          <Typography
            sx={
              isEditScenario
                ? {
                  cursor: "pointer",
                  padding: headersIndent,
                  transition: "padding 0.3s ease-in-out",
                }
                : { pointerEvents: "none", padding: "14px" }
            }
            overflow="hidden"
            className="description-hover"
            onClick={() => setEditDescription(true)}
          >
            {descriptionPlaceholder}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
