import i18next from "i18next";

export const columns = [
  {
    accessorKey: "metric",
    header: "",
    id: "metric",
    size: 200,
    maxSize: 200,
    grow: false,
  },
  {
    accessorKey: "base_to_glide_path",
    header: i18next.t("macro:resultsSection.waterfall.table.baseToGlidePath"),
    id: "base_to_glide_path",
  },
  {
    accessorKey: "glide_path_to_scenario",
    header: i18next.t(
      "macro:resultsSection.waterfall.table.glidePathToScenario",
    ),
    id: "glide_path_to_scenario",
  },
  {
    accessorKey: "base_to_glide_path_per_litre_change",
    header: i18next.t(
      "macro:resultsSection.waterfall.table.baseToGlidePerLitreChange",
    ),
    id: "base_to_glide_path_per_litre_change",
  },
  {
    accessorKey: "glide_path_to_scenario_per_litre_change",
    header: i18next.t(
      "macro:resultsSection.waterfall.table.glideToScenarioPerLitreChange",
    ),
    id: "glide_path_to_scenario_per_litre_change",
  },
];
