import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGlobalLoader } from "../../../components/common";
import "./MacroAdjustmentsPage.scss";
import { useEffect, useState } from "react";
import { useGetMacroScenarioByIdScenariosMacroScenarioIdGet } from "../../../orval/generated/endpoint";
import MacroAdjustmentsPageBody from "../components/macro-adjustments-page-body/MacroAdjustmentsPageBody";
import { MacroToolRoutesConfig } from "../navigation/config";
import MacroAdjustmentsPageHeader from "../components/macro-adjustments-page-header/MacroAdjustmentsPageHeader";

const MacroAdjustmentsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const scenarioId = urlScenarioId || location.state?.id;
  const { showGlobalLoader } = useGlobalLoader();
  const [headersIndent, setHeadersIndent] = useState<string>(
    location.state?.headersIndent || "14px",
  );
  setTimeout(() => setHeadersIndent("0px"), 300);

  const scenarioPageURL = MacroToolRoutesConfig.scenarioPage.replace(
    ":id",
    scenarioId,
  );

  const handleNavigateToResults = () => {
    navigate(scenarioPageURL, {
      state: {
        headersIndent: headersIndent,
      },
    });
  };

  const { data: scenario, isPending: scenarioIsPending } =
    useGetMacroScenarioByIdScenariosMacroScenarioIdGet(parseInt(scenarioId));

  useEffect(() => {
    scenarioIsPending ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [showGlobalLoader, scenarioIsPending]);

  return (
    <>
      {scenario && (
        <>
          <MacroAdjustmentsPageHeader
            scenario={scenario}
            handleNavigateToResults={handleNavigateToResults}
            headersIndent={headersIndent}
          ></MacroAdjustmentsPageHeader>
          <MacroAdjustmentsPageBody
            scenario={scenario}
          ></MacroAdjustmentsPageBody>
        </>
      )}
    </>
  );
};

export default MacroAdjustmentsPage;
