import { AuthenticationProvders } from "./enums";

export const AppConstant = {
  appName: "Product Emissions Forecaster",
  authentication: {
    provider: AuthenticationProvders.IDAM,
  },
  features: {
    generateTemplate: false,
  },
  footerContent: {
    content:
      "© 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details.",
  },
  localStorageKeys: {
    dismissedBannerMessage: "dismissedBannerMessage",
    dismissedMacroNotification: "dismissedMacroNotification",
    appVersion: "appVersion",
    masterDataVersion: "masterDataVersion",
    sandboxMacroScenarioIdKey: "sandboxMacroScenarioIdKey",
    sortValue: "sortValue",
  },
  emptyCell: "--",
};
