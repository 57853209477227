import "../../../common-table/style.scss";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { Box, Typography, useTheme } from "@mui/material";
import { MacroTableWrapper } from "../../../../../../components/common";
type Props = {
  columns: MRT_ColumnDef<any>[];
  data: any;
  title: string;
};

export const OverviewTable = ({ columns, data, title }: Props) => {
  const { palette } = useTheme();

  function getStringBeforeUnderscore(str: string) {
    const indexOfUnderscore = str.indexOf("_");
    if (indexOfUnderscore !== -1) {
      return str.substring(0, indexOfUnderscore);
    } else {
      return str;
    }
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false,
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        color:
          column.id === columns[0].id
            ? "transparent"
            : `${palette.textColor.dark}`,
        borderLeft:
          getStringBeforeUnderscore(column.id) === "base" ||
            getStringBeforeUnderscore(column.id) === "glide"
            ? `2px solid ${palette.textColor.light}`
            : "",
      },
    }),

    muiTableBodyCellProps: ({ column, row, cell }) => ({
      onClick: (event: any) => {
        console.log(column);
      },

      sx: {
        borderBottom:
          cell.id.charAt(0) === `${data.length - 2}`
            ? `1.6px solid ${palette.primary.main}`
            : `1px solid ${palette.textColor.light}`,

        fontWeight:
          cell.id.charAt(0) === `${data.length - 1}` ? "bold" : "initial",
        borderLeft:
          getStringBeforeUnderscore(column.id) === "base" ||
            getStringBeforeUnderscore(column.id) === "glide"
            ? `2px solid ${palette.textColor.light}`
            : "",
      },
    }),
  });

  return (
    <Box className="displayHeader">
      <Box sx={{ textAlign: "start", display: "flex", margin: "1rem 0" }}>
        <Typography className="tableHeader">{title}</Typography>
      </Box>
      <MacroTableWrapper>
        <MaterialReactTable table={table} />
      </MacroTableWrapper>
    </Box>
  );
};
