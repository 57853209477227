import { Checkbox, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import SelectAllRow from "./SelectAllRow";
import NoItemsRow from "./NoItemsRow";

export type SelectDropdownListItem<T extends string | number> = {
  title?: string;
  value: T | SelectDropdownListItem<T>[];
};

type SelectDropdownRowProps<T extends string | number> = {
  index: number;
  filteredItems: SelectDropdownListItem<T>[];
  savedSelectedItems: T[];
  selectedItems: T[];
  addAllItems: () => void;
  allItemsSelected: boolean;
  addItem: (item: T) => void;
  translate?: boolean;
  selectAll?: boolean;
  style?: React.CSSProperties;
};

export function SelectDropdownRow<T extends string | number>({
  index,
  filteredItems,
  savedSelectedItems,
  selectedItems,
  addAllItems,
  allItemsSelected,
  addItem,
  translate,
  selectAll,
  style,
}: SelectDropdownRowProps<T>) {
  const { t } = useTranslation("common");
  const item = filteredItems[index].value;
  const [showSelectAllRow, setShowSelectAllRow] = useState(
    selectAll && filteredItems.length !== 0,
  );
  const [noItemsFound, setNoItemsFound] = useState(filteredItems.length === 0);

  useEffect(() => {
    setShowSelectAllRow(selectAll && filteredItems.length !== 0);
  }, [filteredItems, selectAll]);

  useEffect(() => {
    setNoItemsFound(filteredItems.length === 0);
  }, [filteredItems]);

  if (!item) {
    return null; // Return null if item is undefined
  }

  if (showSelectAllRow && index === 0) {
    return (
      <SelectAllRow
        addAllItems={addAllItems}
        allItemsSelected={allItemsSelected}
      ></SelectAllRow>
    );
  }

  if (noItemsFound) {
    return <NoItemsRow></NoItemsRow>;
  }

  if (typeof item === "string" || typeof item === "number") {
    return (
      <MenuItem
        sx={{ height: "2.5em", maxWidth: "100%", overflow: "hidden" }}
        onClick={() => {
          addItem(item as T);
        }}
        key={item}
        data-testid={`select-dropdown-item-${index}`}
        style={style}
      >
        <ListItemText primary={translate ? t(`${item}`) : item} />
        <Checkbox
          checked={
            selectedItems.includes(item) || savedSelectedItems.includes(item)
          }
          onClick={() => {
            addItem(item as T);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              addItem(item as T);
            }
          }}
          data-testid={`${item}-checkbox`}
        />
      </MenuItem>
    );
  }

  return null;
}
