import i18next from "i18next";
import { ProductColumnData } from "../../types";
import {
  ProductConfigurationsMicro,
  ProductsEditableColumnConfigItem,
  rowValidationType,
  TableData,
  TableRow,
} from "./types";
import { formatNumber } from "../../../../utils/formatNumber/formatNumber";
import { AppConstant } from "../../../../constants";
import { TITLE_COLUMN_WIDTH } from "../../constants";
import { MRT_ColumnDef } from "material-react-table";
import { ColumnWithUnits } from "../ColumnWithUnits";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { revertTextFormat } from "../../../../utils/formatText";
import { ColumnWithDropDown, MicroUnitTypes } from "../ColumnWithDropDown";
import { Tooltip, Box } from "@mui/material";
import {
  Product,
  SKUField,
  SKUFieldAction,
  UserDataTypes,
  ViewMicroScenario,
} from "../../../../orval/generated/models";
import { deepClone } from "../../../../utils";
import { appTheme } from "../../../../core/theme/theme";
import { numberParser } from "utils/numberParser";

const palette = appTheme.palette;

function getProductsEditableTableData(
  dataStructureKey: string,
  metrics: ProductsEditableColumnConfigItem[],
  productColumnData: ProductColumnData[] | undefined,
  products: Product[],
  rowValidationConfig: rowValidationType[],
  useTranslation?: boolean,
  parentAccordion?: string,
): TableData {
  const data: TableData = [];

  metrics.forEach((config: ProductsEditableColumnConfigItem) => {
    const dataObj: TableRow = {
      metric: useTranslation === false ? config.label : i18next.t(config.label),
      dataStructureKey: dataStructureKey,
      subRows: [
        {
          id: "",
          metric: "",
          validationType: "",
          validationIsNonZero: false,
        },
      ],
    };

    let adjustedAction: SKUFieldAction =
      dataStructureKey === ProductConfigurationsMicro.SERVING_SIZE
        ? UserDataTypes.new_value
        : UserDataTypes.percentage;
    let adjustmentsIds: string[] = [];

    productColumnData?.forEach((productColumn: ProductColumnData) => {
      const productData: Product | undefined = products?.find(
        (product: Product) => product.guid === productColumn.guid,
      );

      let flattenedAdjustment = productData?.adjustments
        ? [...productData.adjustments]
        : [];
      productData?.adjustments?.forEach((adj) => {
        if (adj.sub_accordions?.length) {
          flattenedAdjustment = [...flattenedAdjustment, ...adj.sub_accordions];
        }
      });

      const adjustments = flattenedAdjustment.filter(
        (adj) => adj.accordion === dataStructureKey,
      );
      let fields: SKUField[] = [];

      adjustments.forEach((adj) => {
        fields = [...fields, ...(adj.fields as SKUField[])];
      });

      let adjustment = fields.find((f) => f.field_id === config.field_id);

      dataObj[`Product ${productColumn.guid}`] = formatNumber(
        adjustment?.value,
        dataStructureKey,
        parentAccordion,
      );

      if (adjustment?.adjustment_id) {
        adjustedAction = adjustment.action as SKUFieldAction;
        adjustmentsIds.push(adjustment?.adjustment_id);
      }
      dataObj.type = adjustedAction as string;
      dataObj.adjustmentsIds = JSON.stringify(adjustmentsIds);

      dataObj["subRows"][0][`Product ${productColumn.guid}`] = formatNumber(
        adjustment?.adjusted_value,
        dataStructureKey,
        parentAccordion,
      );
      dataObj["subRows"][0]["id"] = "adjusted_value";
      dataObj["subRows"][0]["adjustmentsIds"] = JSON.stringify(adjustmentsIds);

      dataObj["subRows"][0]["metric"] =
        useTranslation === false ? config.label : i18next.t(config.label);
      dataObj["subRows"][0].type = adjustedAction as string;

      const validationType: rowValidationType = rowValidationConfig.find(
        (row: rowValidationType) =>
          row.metric ===
          (useTranslation === false ? config.label : i18next.t(config.label)),
      )!;

      dataObj["subRows"][0]["validationType"] = validationType?.type;
      dataObj["subRows"][0]["validationisNonZero"] = validationType?.isNonZero;
      dataObj["subRows"][0]["dataStructureKey"] = dataStructureKey;
    });
    data.push(dataObj);
  });
  return data;
}

function validateData(
  data: Record<string, string>,
  rowType: any,
  rowValidationType: rowValidationType,
): Record<string, string | undefined> {
  const dataObj = Object.entries(data);
  return dataObj.reduce((acc, data) => {
    const [key, value] = data;

    // ? don't validate the metric column
    if (key === "metric") {
      return acc;
    }

    if (!value) {
      return {
        ...acc,
        [key]: "This field is required",
      };
    }

    const num = numberParser.parse(value);
    // ? don't validate empty cells
    if (value !== AppConstant.emptyCell) {
      if (isNaN(num) || !isFinite(num)) {
        return {
          ...acc,
          [key]: "Value must be a valid number",
        };
      }
    }

    if (rowType === "new_value") {
      if (rowValidationType?.type === "unit") {
        if (rowValidationType.isNonZero && num <= 0) {
          return {
            ...acc,
            [key]: "Value must be greater than 0",
          };
        } else if (!rowValidationType.isNonZero && num < 0) {
          return {
            ...acc,
            [key]: "Value must be greater than or equal to 0",
          };
        }
      } else {
        if (rowValidationType.isNonZero && (num <= 0 || num >= 100)) {
          return {
            ...acc,
            [key]: "Value must be between 0 and 100",
          };
        } else if (!rowValidationType.isNonZero && (num < 0 || num > 100)) {
          return {
            ...acc,
            [key]: "Value must be between 0 and 100, inclusive.",
          };
        }
      }
    } else if (rowType === "percentage") {
      if (rowValidationType.isNonZero && num <= -100) {
        return {
          ...acc,
          [key]: "Value must be greater than -100",
        };
      } else if (!rowValidationType.isNonZero && num < -100) {
        return {
          ...acc,
          [key]: "Value must be greater than or equal to -100",
        };
      }
    }
    return acc;
  }, {});
}

function getTooltipTitle(dataStructureKey: string, value: string) {
  switch (dataStructureKey) {
    case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
      return i18next.t(`micro:toolTip.ef_for_selected_ingredient`);

    case ProductConfigurationsMicro.INGREDIENTS:
      return i18next.t(`micro:toolTip.weight_for_selected_ingredient`);
    case ProductConfigurationsMicro.OTHER_PACKAGING_PRIMARY:
    case ProductConfigurationsMicro.OTHER_PACKAGING_SECONDARY:
    case ProductConfigurationsMicro.OTHER_PACKAGING_TERTIARY:
      if (value.includes("recycle"))
        return i18next.t(
          `micro:toolTip.${value.includes("EF") ? "ef_" : ""}other_packaging_recycled`,
        );

      if (value.includes("weight"))
        return i18next.t(`micro:toolTip.other_packaging_weight`);

      return i18next.t(
        `micro:toolTip.${value.includes("EF") ? "ef_" : ""}other_packaging_virgin`,
      );

    default:
      return i18next.t(`micro:toolTip.${revertTextFormat(value as string)}`);
  }
}

function getTableColumns(
  products: Array<any>,
  metrics?: ProductsEditableColumnConfigItem[],
  size: number = TITLE_COLUMN_WIDTH,
  showToolTip?: boolean,
  showDropDown?: boolean,
  isEditing?: boolean,
): MRT_ColumnDef<any>[] {
  const tableColumns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "metric", //access nested data with dot notation
      header: "Metric",
      size: size,
      id: "metric",
      enableEditing: false,
      grow: false,
      Cell: ({ renderedCellValue, row }) => {
        return (
          <>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"space-between"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <ColumnWithUnits
                  renderedCellValue={renderedCellValue}
                  row={row}
                  metrics={metrics}
                />

                {showToolTip ? (
                  <Box ml={1} display={"flex"} alignItems={"center"}>
                    <Tooltip
                      title={getTooltipTitle(
                        row.original.dataStructureKey,
                        renderedCellValue as string,
                      )}
                      arrow
                      placement="top"
                    >
                      <ErrorOutlineIcon
                        sx={{
                          color: `${palette.primary.main}`,
                          transform: "rotate(180deg)",
                          fontSize: "18px",
                        }}
                      />
                    </Tooltip>
                  </Box>
                ) : null}
                {showDropDown &&
                row?.original?.id !== "new_serving_size" &&
                !row.originalSubRows ? (
                  <Box>
                    <ColumnWithDropDown row={row} isEditing={isEditing} />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  products.forEach((product: ProductColumnData) => {
    tableColumns.push({
      accessorKey: `Product ${product.guid}`,
      header: product.customProductName
        ? product.customProductName
        : product.name,
      id: `Product ${product.guid}`,
      meta: { guid: product.guid, hasAdjustments: product.hasAdjustments },
    });
  });

  return tableColumns;
}

function updateScenarioDataForTheRow(
  scenario: ViewMicroScenario,
  dataStructureKey: string,
  keyToUpdate: string,
  updatedData: any,
  products?: ProductColumnData[],
  rowType?: any,
): any {
  //TODO:  Use new Model
  const updatedColumns = Object.keys(updatedData).filter(
    (column: string) => column !== "metric",
  );
  const _scenario = deepClone(scenario);

  updatedColumns.forEach((column: string) => {
    const productId = column.replace("_user_data", ""); // this is a hotfix to fix the columns
    const productdata = products?.filter(
      (product: ProductColumnData) =>
        product.name.split(" ").join("_") === productId,
    );
    const productName = productdata ? productdata[0].name : "";

    const dataToUpdateForProduct = {
      [keyToUpdate]: {
        type:
          keyToUpdate === "serving_size"
            ? UserDataTypes.new_value
            : (rowType ?? MicroUnitTypes.OVERRIDE),
        value: updatedData[column] ? Number(updatedData[column]) : null,
      },
    };

    if (productName) {
      if (
        _scenario.inputs[productName] &&
        !_scenario.inputs[productName]?.user_data
      ) {
        _scenario.inputs[productName] = Object.assign(
          _scenario.inputs[productName],
          {
            user_data: {
              [dataStructureKey]: {},
            },
          },
        );
      }

      Object.assign(
        _scenario.inputs[productName]?.user_data[dataStructureKey],
        dataToUpdateForProduct,
      );
    }
  });
  return _scenario as ViewMicroScenario;
}

export {
  getProductsEditableTableData,
  validateData,
  getTableColumns,
  updateScenarioDataForTheRow,
};
