import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  getWaterfallData,
  getWaterfallLabels,
  getTableData,
} from "./HelperService";
import { OptionalMetricsTable } from "../optional-metrics-table/OptionalMetricsTable";
import { columns } from "./TableColumns";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";
import { TableResultsType } from "../scenario-results/results-tab-section/types";
import WaterfallChart from "./WaterfallChart";

type Props = {
  scenario: ViewMacroScenarioViewModel | null;
  sectionType: TableResultsType;
};

type chartData = {
  label: string;
  data: (number[] | null)[];
  backgroundColor: string;
}[];

function WaterfallChartSection({ sectionType, scenario }: Props) {
  const { t } = useTranslation("macro");
  const [labels, setLabels] = useState<string[] | []>([]);
  const [dataSet, setDataSet] = useState<chartData>([]);

  const convertedType =
    sectionType === TableResultsType.BY_CATEGORY ? "by_category" : "by_pack";

  useEffect(() => {
    if (scenario && scenario?.results?.waterfall.chart) {
      const selectedData = scenario?.results?.waterfall.chart[convertedType];
      if (selectedData) {
        setDataSet(getWaterfallData(selectedData, scenario?.inputs?.years));
        setLabels(getWaterfallLabels(selectedData, scenario?.inputs?.years));
      }
    }
  }, [scenario, convertedType]);

  return (
    <>
      <Box gap="48px" display="flex" flexDirection="column" data-testid="waterfall-section">
        <Box mt={2}>
          <WaterfallChart
            labels={labels}
            datasets={dataSet}
            title={t("resultsSection.waterfall.chartTitle")}
            subtitle={t("resultsSection.waterfall.chartSubtitle")}
          />
        </Box>
        <Box display="flex" alignItems="end" gap="32px" borderBottom="1px solid #CBCBCB">
          <OptionalMetricsTable
            columns={columns}
            data={getTableData(scenario?.results?.waterfall.table, convertedType)}
            displaySubtitle
            highlightLastColumn={false}
            title={t("resultsSection.waterfall.table.title")}
            subtitle={t("resultsSection.waterfall.table.subTitle")}
          />
        </Box>
      </Box>
    </>
  );
}

export default WaterfallChartSection;
