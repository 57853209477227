import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useLocation, useParams } from "react-router-dom";
import { ProductsSection, ResultsSection } from "../../components";
import { useModal } from "../../../../components/common/modal";
import LandingPageHeroSection from "../../components/LandingPageHeroSection/LandingPageHeroSection";
import { ProductColumnData } from "../../types";
import { ProductSelection } from "../../../../components/common/product-selection";
import { getTableColumns } from "../../components";
import EditProductModal from "../../components/ProductsSection/EditProductModal";
import AdjustmentsSection from "../../components/AdjustmentsSection/AdjustmentsSection";
import { useGlobalLoader } from "../../../../components/common";
import {
  ViewMicroScenario,
  UpdateScenarioDetails,
  Product,
  EditScenarioSkuModel,
  SKUMinimalViewModel,
} from "../../../../orval/generated/models";
import { ProductsHeaderTable } from "../../components/ProductsSection/ProductsHeaderTable";
import { COLUMN_WIDTH } from "../../constants";
import { useSnackbar } from "../../../../components/common/notification/showSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import {
  useGetMicroScenarioByIdScenariosMicroScenarioIdGet,
  useGetSkusSkusGet,
  useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch,
  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey,
  useAddProductsToMicroScenarioScenariosMicroScenarioIdProductsPost,
  useCopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost,
  useUpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch,
  useDuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost,
  useDeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete,
  useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete,
  useReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPost,
  exportMicroScenarioScenariosMicroScenarioIdExportGet,
} from "../../../../orval/generated/endpoint";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import { withCustomAxios } from "../../../../orval/mutator/custom-instance";
import getFormattedDateForDownloads from "utils/getFormattedDateForDownloads";

function MicroToolScenarioPage() {
  const { t } = useTranslation("micro");
  const { openModal, closeModal } = useModal();
  const [productColumns, setProductColumns] = useState<ProductColumnData[]>([]);
  const [productsData, setProductsData] = useState<Array<any>>([]);
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  const { showGlobalLoader } = useGlobalLoader();
  const location = useLocation();
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const getInitialScenarioId = () => {
    return urlScenarioId || location.state?.id;
  };
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const [pageSetupIsPending, setPageSetupIsPending] = useState(true);

  const {
    data: scenarioDetails,
    isPending: scenarioIsPending,
    error: errorFetchingScenarioDetails,
  } = useGetMicroScenarioByIdScenariosMicroScenarioIdGet(
    parseInt(getInitialScenarioId()),
  );

  const {
    mutateAsync: updateMicroScenario,
    isPending: updateMicroScenarioIsPending,
  } = useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch({
    mutation: {
      onSuccess: (data: ViewMicroScenario) => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            parseInt(getInitialScenarioId()),
          ),
        });
        setupPageData(data);
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: addProductsToMicroScenario,
    isPending: addProductsToMicroScenarioIsPending,
    isSuccess: addProductsToMicroScenarioIsSuccess,
  } = useAddProductsToMicroScenarioScenariosMicroScenarioIdProductsPost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            parseInt(getInitialScenarioId()),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const { mutateAsync: reOrderProducts, isPending: reOrderProductsIsPending } =
    useReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPost({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey:
              getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                parseInt(getInitialScenarioId()),
              ),
          });
          showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
        },
        onError: (error: any) => {
          showSnackbar(t("errorMessages.errorUpdating"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const {
    mutateAsync: deleteAdjustments,
    isPending: deleteAdjustmentsIsPending,
  } = useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            parseInt(getInitialScenarioId()),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  function findMatchingSkus(
    products: Product[],
    skusData: SKUMinimalViewModel[],
  ): SKUMinimalViewModel[] {
    const productNames = products.map((product) => product.product_name);
    return skusData.filter((sku) => productNames.includes(sku.product_name));
  }

  const handleAddProducts = async (selectedProducts: Product[]) => {
    try {
      const result = await addProductsToMicroScenario({
        scenarioId: getInitialScenarioId(),
        data:
          (skusData &&
            findMatchingSkus(selectedProducts, skusData).map(
              (product) => product.id,
            )) ||
          [],
      });

      const resultsHashmap: { [key: string]: string } = {};
      result.forEach((product) => {
        const mapKey = product.custom_display_name
          ? product.custom_display_name
          : product.product_name;
        if (!resultsHashmap[mapKey]) resultsHashmap[mapKey] = product.guid;
      });

      const data = selectedProducts.map(
        (product) =>
          resultsHashmap[
            product.custom_display_name
              ? product.custom_display_name
              : product.product_name
          ],
      );

      if (data.length > 0) {
        await reOrderProducts({
          scenarioId: getInitialScenarioId(),
          data: data,
        });
      }
      closeModal();
    } catch (e) {
      showSnackbar(t("errorMessages.errorUpdating"), "error");
      console.warn(e);
    }
  };

  const {
    data: skusData,
    isPending: skusIsPending,
    isError: errorFetchingSkus,
  } = useGetSkusSkusGet();

  useEffect(() => {
    scenarioIsPending ||
    skusIsPending ||
    pageSetupIsPending ||
    deleteAdjustmentsIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    scenarioIsPending,
    skusIsPending,
    pageSetupIsPending,
    showGlobalLoader,
    deleteAdjustmentsIsPending,
  ]);

  const setupPageData = useCallback(
    (scenarioDetails: ViewMicroScenario) => {
      const _products: ProductColumnData[] = [];

      const productsHashMap: { [key: string]: Product } = {};
      scenarioDetails.products.forEach((product) => {
        productsHashMap[product.custom_display_name || product.product_name] =
          product;
      });

      scenarioDetails.products.forEach((product: Product) => {
        const productData =
          productsHashMap[product.custom_display_name || product.product_name];
        _products.push({
          name: productData.product_name ?? "",
          guid: productData.guid,
          customProductName: productData.custom_display_name ?? null,
          hasAdjustments: productData.has_adjustments,
        });
      });

      setProductColumns(_products);
      setProductsData(
        scenarioDetails.products.map((product) => ({
          ...product,
          id: skusData?.find(
            (sku: { product_name: string }) =>
              sku.product_name === product.product_name,
          )?.id,
        })),
      );
      setColumns(getTableColumns(_products, undefined, COLUMN_WIDTH, false));
      setPageSetupIsPending(false);
    },
    [skusData],
  );

  useEffect(() => {
    scenarioDetails && setupPageData(scenarioDetails);
    if (errorFetchingScenarioDetails) {
      showSnackbar(t("errorMessages.errorFetchingScenarioDetails"), "error");
    }
  }, [
    scenarioDetails,
    errorFetchingScenarioDetails,
    skusIsPending,
    setupPageData,
    showSnackbar,
    t,
  ]);

  useEffect(() => {
    if (errorFetchingSkus) {
      showSnackbar("errorMessages.errorNoProducts", "error");
    }
  }, [skusIsPending, showGlobalLoader, errorFetchingSkus, showSnackbar]);

  const handleExportResults = async () => {
    showGlobalLoader(true);
    try {
      const response: typeof exportMicroScenarioScenariosMicroScenarioIdExportGet =
        await withCustomAxios({
          url: `/scenarios/micro/${getInitialScenarioId()}/export`,
          method: "GET",
          responseType: "blob",
        });

      const blob = new Blob([response as unknown as BlobPart], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.download = `PEF-${scenarioDetails?.name}-${getFormattedDateForDownloads()}.xlsx`;
      link.click();

      URL.revokeObjectURL(url);

      showSnackbar(t("exportSuccess"), "success");
    } catch (error) {
      console.warn(error);
      showSnackbar(t("exportFailed"), "error");
    } finally {
      showGlobalLoader(false);
    }
  };

  const handleUpdateScenarioDetails = async (data: {
    id: number;
    payload: UpdateScenarioDetails;
  }) => {
    try {
      await updateMicroScenario({
        scenarioId: data.id,
        data: data.payload,
      });
    } catch (error) {
      showSnackbar(
        t("microViewAllScenariosPage.updateDetailsModal.duplicateError"),
        "error",
      );
      queryClient.resetQueries({
        queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
          parseInt(getInitialScenarioId()),
        ),
      });
    }
  };

  const handleRenameScenario = async (value: string) => {
    if (
      scenarioDetails &&
      scenarioDetails.name !== value &&
      scenarioDetails.id
    ) {
      const _updateScenarioPayload: UpdateScenarioDetails = {
        name: value,
      };
      await handleUpdateScenarioDetails({
        id: scenarioDetails.id,
        payload: _updateScenarioPayload,
      });
    }
  };

  const handleRenameDescription = async (value: string) => {
    if (
      scenarioDetails &&
      scenarioDetails.description !== value &&
      scenarioDetails.id
    ) {
      if (value.length > 255) {
        showSnackbar(t("errorMessages.shorterDescription"), "error");
        return;
      }

      const _updateScenarioPayload: UpdateScenarioDetails = {
        description: value,
      };
      await handleUpdateScenarioDetails({
        id: scenarioDetails.id,
        payload: _updateScenarioPayload,
      });
    }
  };

  const handleSelectProduct = () => {
    if (
      skusData &&
      !pageSetupIsPending &&
      !addProductsToMicroScenarioIsPending
    ) {
      openModal(
        <ProductSelection
          closeModal={closeModal}
          products={productsData}
          skus={skusData}
          scenarioDetails={scenarioDetails as ViewMicroScenario}
          handleAddProducts={handleAddProducts}
        />,
        true,
        "lg",
      );
    }
  };

  const handleClearAllProducts = async () => {
    try {
      showGlobalLoader(true);
      await addProductsToMicroScenario({
        scenarioId: getInitialScenarioId(),
        data: [],
      });
    } catch (e) {
      showSnackbar(t("errorMessages.errorUpdating"), "error");
    } finally {
      showGlobalLoader(false);
      closeModal();
    }
  };

  const onHandleClearProducts = () => {
    if (productColumns) {
      openModal(
        <ConfirmationModal
          title={t("micro:productsSection.clearProductsModal.title")}
          description={t(
            "micro:productsSection.clearProductsModal.description",
          )}
          actionTitle={t("common:actions.clear")}
          confirmAction={handleClearAllProducts}
          cancelAction={closeModal}
        />,
      );
    }
  };

  const onHandleClearAdjustments = () => {
    if (productColumns) {
      openModal(
        <ConfirmationModal
          title={t("micro:productsSection.clearAdjustmentsModal.title")}
          description={t(
            "micro:productsSection.clearAdjustmentsModal.description",
          )}
          actionTitle={t("common:actions.clear")}
          confirmAction={() => {
            handleClearAdjustments();
            closeModal();
          }}
          cancelAction={closeModal}
        />,
      );
    }
  };

  const getAdjustmentsIds = () => {
    let adjustmentsIds: string[] = [];

    scenarioDetails?.products.forEach((product) => {
      product?.adjustments.forEach((adjustment) => {
        if (adjustment.sub_accordions?.length) {
          adjustment.sub_accordions.forEach((sub_accordion) => {
            sub_accordion.fields?.forEach((field) => {
              if (field.adjustment_id) adjustmentsIds.push(field.adjustment_id);
            });
          });
        } else {
          adjustment.fields?.forEach((field) => {
            if (field.adjustment_id) adjustmentsIds.push(field.adjustment_id);
          });
        }
      });
    });

    return adjustmentsIds;
  };

  const handleClearAdjustments = async () => {
    const adjustmentsIds = getAdjustmentsIds();

    if (adjustmentsIds.length) {
      try {
        await deleteAdjustments({
          scenarioId: getInitialScenarioId(),
          data: { adjustment_guids: adjustmentsIds },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openCopyToAllChangesModal = (productGuid: string) => {
    openModal(
      <ConfirmationModal
        title={t("micro:productsSection.copyAdjustmentsToAllModal.title")}
        description={t(
          "micro:productsSection.copyAdjustmentsToAllModal.description",
        )}
        actionTitle={t("common:actions.change")}
        confirmAction={() => {
          handleCopyAdjustmentsToAllProducts(productGuid);
          closeModal();
        }}
        cancelAction={closeModal}
      />,
    );
  };

  const handleCopyAdjustmentsToAllProducts = async (productGuid: string) => {
    if (scenarioDetails) {
      try {
        showGlobalLoader(true);
        await copyAdjustmentsToAllProducts({
          scenarioId: scenarioDetails.id,
          productGuid: productGuid,
        });
      } catch (error) {
        showSnackbar(
          t("errorMessages.errorCopyingChangesToAllProducts"),
          "error",
        );
        console.warn(error);
      } finally {
        showGlobalLoader(false);
      }
    }
  };

  const {
    mutateAsync: copyAdjustmentsToAllProducts,
    isPending: copyAdjustmentsToAllProductsIsPending,
  } =
    useCopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost(
      {
        mutation: {
          onSuccess: () => {
            if (scenarioDetails) {
              queryClient.invalidateQueries({
                queryKey:
                  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                    scenarioDetails.id,
                  ),
              });
            }
          },
          onError: (error: any) => {
            showSnackbar(t("errorMessages.errorCopyingAdjustments"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const {
    mutateAsync: duplicateProductOnScenario,
    isPending: duplicateProductOnScenarioIsPending,
  } =
    useDuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost(
      {
        mutation: {
          onSuccess: () => {
            if (scenarioDetails) {
              queryClient.invalidateQueries({
                queryKey:
                  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                    scenarioDetails.id,
                  ),
              });
            }
          },
          onError: (error) => {
            showSnackbar(t("errorMessages.errorDuplicatingProduct"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const handleDuplicateProduct = async (productGuid: string) => {
    if (scenarioDetails) {
      try {
        showGlobalLoader(true);
        await duplicateProductOnScenario({
          scenarioId: scenarioDetails.id,
          productGuid,
        });
      } catch (error) {
        showSnackbar(t("errorMessages.errorDuplicatingProduct"), "error");
        console.warn(error);
      } finally {
        showGlobalLoader(false);
      }
    }
  };

  const {
    mutateAsync: deleteProductOnScenario,
    isPending: deleteProductsOnSkuIsPending,
  } =
    useDeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete({
      mutation: {
        onSuccess: () => {
          if (scenarioDetails) {
            queryClient.invalidateQueries({
              queryKey:
                getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                  scenarioDetails.id,
                ),
            });
          }
        },
        onError: (error) => {
          showSnackbar(t("errorMessages.errorDeletingProduct"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const handleDeleteProducts = async (productGuid: string) => {
    if (scenarioDetails) {
      try {
        showGlobalLoader(true);
        await deleteProductOnScenario({
          scenarioId: scenarioDetails.id,
          productGuid,
        });
      } catch (error) {
        showSnackbar(t("errorMessages.errorDeletingProduct"), "error");
        console.warn(error);
      } finally {
        showGlobalLoader(false);
      }
    }
  };

  const openConfirmDeleteProductModal = (productGuid: string) => {
    openModal(
      <ConfirmationModal
        title={t("micro:productsSection.deleteProductModal.title")}
        description={t("micro:productsSection.deleteProductModal.description")}
        actionTitle={t("common:actions.remove")}
        confirmAction={() => {
          handleDeleteProducts(productGuid);
          closeModal();
        }}
        cancelAction={closeModal}
      />,
    );
  };

  const openEditProductModal = (productGuid: string) => {
    if (scenarioDetails) {
      openModal(
        <EditProductModal
          productGuid={productGuid}
          closeModal={closeModal}
          scenarioDetails={scenarioDetails}
          handleUpdateProductDisplayName={handleUpdateProductDisplayName}
        />,
      );
    }
    return;
  };

  const {
    mutateAsync: updateProductDisplayName,
    isPending: updateProductDisplayNameIsPending,
  } = useUpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch(
    {
      mutation: {
        onSuccess: () => {
          if (scenarioDetails) {
            queryClient.invalidateQueries({
              queryKey:
                getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                  scenarioDetails.id,
                ),
            });
          }
        },
        onError: (error) => {
          showSnackbar(t("errorMessages.errorUpdatingProductName"), "error");
          console.warn(error);
          return error;
        },
      },
    },
  );

  const handleUpdateProductDisplayName = async (data: {
    scenarioId: number;
    productId: string;
    data: EditScenarioSkuModel;
  }) => {
    try {
      showGlobalLoader(true);
      await updateProductDisplayName({
        scenarioId: data.scenarioId,
        productGuid: data.productId,
        data: data.data,
      });
    } catch (error) {
      showSnackbar(t("errorMessages.errorUpdatingProductName"), "error");
      console.warn(error);
    } finally {
      showGlobalLoader(false);
    }
  };

  useEffect(() => {
    updateMicroScenarioIsPending ||
    addProductsToMicroScenarioIsPending ||
    scenarioIsPending ||
    skusIsPending ||
    pageSetupIsPending ||
    deleteProductsOnSkuIsPending ||
    duplicateProductOnScenarioIsPending ||
    updateProductDisplayNameIsPending ||
    copyAdjustmentsToAllProductsIsPending ||
    reOrderProductsIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    updateMicroScenarioIsPending,
    scenarioIsPending,
    skusIsPending,
    pageSetupIsPending,
    showGlobalLoader,
    addProductsToMicroScenarioIsPending,
    deleteProductsOnSkuIsPending,
    duplicateProductOnScenarioIsPending,
    updateProductDisplayNameIsPending,
    copyAdjustmentsToAllProductsIsPending,
    reOrderProductsIsPending,
  ]);

  useEffect(() => {
    if (
      !scenarioIsPending &&
      !pageSetupIsPending &&
      !addProductsToMicroScenarioIsPending &&
      addProductsToMicroScenarioIsSuccess &&
      scenarioDetails?.products &&
      scenarioDetails?.products?.length < 1
    ) {
      handleSelectProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageSetupIsPending,
    scenarioDetails,
    addProductsToMicroScenarioIsPending,
    addProductsToMicroScenarioIsSuccess,
    scenarioIsPending,
  ]);

  return (
    <Box height={"100%"} minWidth={"91vw"}>
      <Box sx={{ backgroundColor: "white" }}>
        <LandingPageHeroSection
          title={scenarioDetails?.name || ""}
          description={scenarioDetails?.description || ""}
          onExportResults={handleExportResults}
          onRenameScenario={handleRenameScenario}
          handleRenameDescription={handleRenameDescription}
          onSelectProduct={handleSelectProduct}
          isEditScenario={!!scenarioDetails}
          handleClearProducts={onHandleClearProducts}
          handleClearAdjustments={onHandleClearAdjustments}
          products={productColumns}
          skusIsPending={skusIsPending}
          hasAdjustments={Boolean(scenarioDetails?.has_adjustments)}
        />
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 5,
              backgroundColor: "white",
            }}
          >
            <ProductsHeaderTable
              openCopyToAllChangesModal={openCopyToAllChangesModal}
              handleDuplicateProduct={handleDuplicateProduct}
              openEditProductModal={openEditProductModal}
              openConfirmDeleteProductModal={openConfirmDeleteProductModal}
              scenario={scenarioDetails as ViewMicroScenario}
              products={productColumns}
            />
          </Box>
          <Box
            sx={{
              height: "calc(100vh - 423px)",
              overflowY: "auto",
              minWidth: "calc(100vw - 120px)",
              paddingBottom: "40px",
            }}
          >
            <ProductsSection
              disabled={!scenarioDetails}
              products={productColumns}
              scenario={scenarioDetails}
            />
            {productColumns && productColumns.length > 0 && (
              <Box>
                <AdjustmentsSection
                  disabled={!scenarioDetails}
                  productColumnData={productColumns}
                  scenario={scenarioDetails}
                />
                <ResultsSection
                  disabled={!scenarioDetails}
                  columns={columns}
                  scenario={scenarioDetails}
                  products={productColumns}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MicroToolScenarioPage;
