import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import SingleScenario from "./SingleScenario";
import { Loader, SearchBar } from "../../../../components/common";
import { SortConfig, SortValues } from "../../../../utils/SortConfig";
import Sort from "../../../../components/common/sort-dropdown/Sort";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";
import {
  useGetAllMacroScenariosScenariosMacroGet,
  useGetMemberCountriesMembersCountriesGet,
  useGetYearsYearsGet,
} from "../../../../orval/generated/endpoint";
import { useSnackbar } from "../../../../components/common/notification/showSnackbar";
import SelectDropdown from "../../../../components/common/select-dropdown/SelectDropdown";
import { AxiosError } from "axios";

type ScenarioListProps = {
  sandboxMacroScenarioIdKey: string;
  sandboxMacroScenarioID: string | null;
  setSandboxMacroScenarioID: Dispatch<SetStateAction<string | null>>;
  chosenScenarios: string[];
  setChosenScenarios: Dispatch<SetStateAction<string[]>>;
  chosenBaseYears: number[];
  setChosenBaseYears: Dispatch<SetStateAction<number[]>>;
};

function ScenarioList({
  sandboxMacroScenarioIdKey,
  sandboxMacroScenarioID,
  setSandboxMacroScenarioID,
  chosenScenarios,
  setChosenScenarios,
  setChosenBaseYears,
  chosenBaseYears,
}: ScenarioListProps) {
  const defaultSortValue = SortValues.DATE_UPDATED_DESCENDING;
  const { t } = useTranslation("macro");
  const showSnackbar = useSnackbar();

  const [searchValue, setSearchValue] = useState<string>("");
  const [sortValue, setSortValue] = useState<SortValues>(defaultSortValue);
  const [sortedData, setSortedData] = useState<ViewMacroScenarioViewModel[]>(
    [],
  );
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedTargetYears, setSelectedTargetYears] = useState<number[]>([]);

  const {
    data: scenarioData,
    isPending: scenarioDataIsPending,
    error: scenarioDataError,
  } = useGetAllMacroScenariosScenariosMacroGet();
  const { data: countriesData, isError: errorFetchingCountries } =
    useGetMemberCountriesMembersCountriesGet();
  const { data: yearsData, isError: errorFetchingYears } =
    useGetYearsYearsGet();

  useEffect(() => {
    if (scenarioDataError) {
      showSnackbar(scenarioDataError as AxiosError<unknown, any>, "info");
    }
  }, [scenarioDataIsPending]);

  useEffect(() => {
    if (errorFetchingCountries || errorFetchingYears) {
      showSnackbar(t("errorMessages.getScenarioDetails"), "error");
    }
  }, [errorFetchingCountries, errorFetchingYears]);

  function onChangeSelectOption(
    item: number,
    checked: boolean,
    base_year: number | null,
  ): void {
    if (checked) {
      setChosenScenarios([...chosenScenarios, item.toString()]);
      setChosenBaseYears([...chosenBaseYears, base_year as number]);
    } else {
      setChosenScenarios(
        chosenScenarios.filter(
          (selectedItem) => selectedItem !== item.toString(),
        ),
      );
      setChosenBaseYears(
        chosenBaseYears.filter((selectedItem) => selectedItem !== base_year),
      );
    }
  }

  function onSearchChange(value: string): void {
    setSearchValue(value);
    applyDataFilters();
  }

  const applyDataFilters = useCallback(() => {
    if (!scenarioData) return;

    let filteredData = scenarioData?.filter(
      (scenario: ViewMacroScenarioViewModel) => {
        const nameIncludesSearchValue = searchValue
          ? scenario.name.toLowerCase().includes(searchValue.toLowerCase())
          : true;
        const hasSelectedCountry =
          selectedCountries.length > 0
            ? scenario.inputs?.countries?.some((country) =>
              selectedCountries.includes(country.display_name),
            )
            : true;
        const hasSelectedTargetYear =
          selectedTargetYears.length > 0 && scenario.inputs?.years?.target_year
            ? selectedTargetYears.includes(scenario.inputs?.years?.target_year)
            : true;
        return (
          nameIncludesSearchValue && hasSelectedCountry && hasSelectedTargetYear
        );
      },
    );

    setSortedData(filteredData.sort(getSortFunction(sortValue)));
  }, [
    scenarioData,
    searchValue,
    selectedCountries,
    sortValue,
    selectedTargetYears,
  ]);

  function getSortFunction(sortValue: SortValues) {
    switch (sortValue) {
      case SortValues.DATE_UPDATED_ASCENDING:
        return (a: ViewMacroScenarioViewModel, b: ViewMacroScenarioViewModel) =>
          new Date(a.updated_at!).getTime() - new Date(b.updated_at!).getTime();
      case SortValues.DATE_UPDATED_DESCENDING:
        return (a: ViewMacroScenarioViewModel, b: ViewMacroScenarioViewModel) =>
          new Date(b.updated_at!).getTime() - new Date(a.updated_at!).getTime();
      case SortValues.NAME_ASCENDING:
        return (a: ViewMacroScenarioViewModel, b: ViewMacroScenarioViewModel) =>
          a.name.localeCompare(b.name);
      case SortValues.NAME_DESCENDING:
        return (a: ViewMacroScenarioViewModel, b: ViewMacroScenarioViewModel) =>
          b.name.localeCompare(a.name);
      default:
        return (a: ViewMacroScenarioViewModel, b: ViewMacroScenarioViewModel) =>
          0;
    }
  }

  useEffect(() => {
    if (!sandboxMacroScenarioID && sortedData.length) {
      localStorage.setItem(
        sandboxMacroScenarioIdKey,
        sortedData[0].id!.toString(),
      );
      setSandboxMacroScenarioID(sortedData[0].id!.toString());
    }
  }, [
    sandboxMacroScenarioIdKey,
    setSandboxMacroScenarioID,
    sandboxMacroScenarioID,
    sortedData,
  ]);

  const handleCountriesTypeChange = (newSelectedCountries: string[]) => {
    setSelectedCountries(newSelectedCountries);
  };

  const handleYearSelectionChange = (newSelectedYears: number[]) => {
    setSelectedTargetYears(newSelectedYears);
  };

  useEffect(() => {
    if (scenarioData) {
      applyDataFilters();
    }
  }, [scenarioData, applyDataFilters]);

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item lg={5} sm={12}>
            <SearchBar
              handleSearch={onSearchChange}
              disabled={scenarioData && scenarioData?.length < 1}
            />
          </Grid>

          {countriesData && (
            <Grid item lg sm mt={"4px"}>
              <SelectDropdown
                listItems={countriesData.map((country) => country.display_name)}
                savedSelectedItems={selectedCountries}
                onSave={handleCountriesTypeChange}
                title={t("countryDropdown.countryDropdownPlaceholder")}
                compact
                showSearchBar={false}
                selectAll={true}
                disabled={scenarioData && scenarioData?.length < 1}
              ></SelectDropdown>
            </Grid>
          )}

          {yearsData && (
            <Grid item lg sm mt={"4px"}>
              <SelectDropdown<number>
                listItems={yearsData.map((year) => year.year)}
                savedSelectedItems={selectedTargetYears}
                onSave={handleYearSelectionChange}
                title={t("targetYearDropdown.targetYearDropdownPlaceholder")}
                compact
                disabled={scenarioData && scenarioData?.length < 1}
              ></SelectDropdown>
            </Grid>
          )}

          <Grid item lg sm>
            <Sort<SortValues>
              sortValue={sortValue}
              setSortValue={setSortValue}
              values={SortConfig}
              disabled={scenarioData && scenarioData?.length < 1}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={2}>
        {scenarioDataIsPending ? (
          <Loader />
        ) : sortedData.length > 0 ? (
          sortedData.map((data) => (
            <Box key={data.id}>
              <SingleScenario
                selectedOptions={chosenScenarios}
                onChangeSelectOption={onChangeSelectOption}
                scenarioId={data.id ?? 0}
                data={data}
                key={data.id}
              />
            </Box>
          ))
        ) : (
          <>
            <Box className="no-scenarios-box">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box pb={3}>
                  <Typography className="header-H2">
                    {t("common:noData.emptyStateMessage1")}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="header-H3">
                    {t("common:noData.emptyStateMessage2")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default ScenarioList;
