import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import { ScenarioResults } from "../components";
import {
  DateAndCountryInputSection,
  MacroPageHeroSection,
} from "../components/macro-hero-section";
import { Adjustment } from "../types";
import { MacroToolRoutesConfig } from "../navigation/config";
import { useGlobalLoader } from "../../../components/common";
import { ManageExport } from "../components/export-sidePanel/ManageExport";
import { Typography } from "@mui/material";
import "./MacroToolScenarioPage.scss";
import {
  UpdateMacroScenarioViewModel,
  ViewMacroScenarioViewModel,
} from "../../../orval/generated/models";
import { useSnackbar } from "../../../components/common/notification/showSnackbar";
import { deepClone } from "../../../utils";
import { useQueryClient } from "@tanstack/react-query";
import {
  getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey,
  useGetMacroScenarioByIdScenariosMacroScenarioIdGet,
  useGetMemberCountriesMembersCountriesGet,
  useGetYearsYearsGet,
  useUpdateMacroScenarioScenariosMacroScenarioIdPatch,
} from "../../../orval/generated/endpoint";
import { AxiosError } from "axios";

const redirectURL = MacroToolRoutesConfig.countryLevelAnalytics;

function MacroToolScenarioPage() {
  const queryClient = useQueryClient();
  const { t } = useTranslation("macro");
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { showGlobalLoader } = useGlobalLoader();
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const [open, setOpen] = useState<boolean>(false);
  const [resultsHidden, setResultsHidden] = useState<boolean>(false);
  const scenarioId = urlScenarioId || location.state?.id;

  const {
    data: scenario,
    isPending: scenarioIsPending,
    error: scenarioError,
  } = useGetMacroScenarioByIdScenariosMacroScenarioIdGet(parseInt(scenarioId));
  const { data: years } = useGetYearsYearsGet();
  const { data: countries } = useGetMemberCountriesMembersCountriesGet();
  const [headersIndent, setHeadersIndent] = useState<string>(
    location.state?.headersIndent || "14px",
  );
  if (headersIndent !== "14px") {
    setTimeout(() => setHeadersIndent("14px"), 300);
  }

  useEffect(() => {
    if (scenarioError) {
      showSnackbar(scenarioError as AxiosError<unknown, any>, "info");
    }
  }, [scenarioIsPending]);

  const {
    mutateAsync: updateMacroScenario,
    isPending: updateMacroScenarioIsPending,
  } = useUpdateMacroScenarioScenariosMacroScenarioIdPatch({
    mutation: {
      onSuccess: (data: ViewMacroScenarioViewModel) => {
        scenario &&
          scenario.id &&
          queryClient.invalidateQueries({
            queryKey:
              getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey(
                scenario.id,
              ),
          });
        showSnackbar(t("notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.updateScenario"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const handleRenameScenario = async (value: string) => {
    if (scenario && scenario.name !== value && scenario.id) {
      const _scenarioToUpdate: ViewMacroScenarioViewModel = deepClone(scenario);
      _scenarioToUpdate.name = value;
      const _updateScenarioPayload: UpdateMacroScenarioViewModel = {
        name: _scenarioToUpdate.name,
        description: _scenarioToUpdate.description,
        inputs: _scenarioToUpdate.inputs,
      };
      if (scenario.id) {
        try {
          await updateMacroScenario({
            scenarioId: scenario.id,
            data: _updateScenarioPayload,
          });
        } catch (error) {
          showSnackbar(t("errorMessages.duplicateName"), "error");
        }
      }
    }
  };

  const handleRenameDescription = (value: string) => {
    const scenarioDescriptionHasBeenChanged =
      scenario && scenario.description !== value;
    if (scenarioDescriptionHasBeenChanged) {
      const scenarioUpdates: UpdateMacroScenarioViewModel = {
        name: scenario.name,
        description: value,
        inputs: scenario.inputs,
      };
      scenario.id &&
        updateMacroScenario({ scenarioId: scenario.id, data: scenarioUpdates });
    }
  };

  // ? Hides results if no base year or target year
  useEffect(() => {
    const allFieldsFilled =
      scenario &&
      scenario.results &&
      (scenario as ViewMacroScenarioViewModel)?.inputs?.years?.base_year &&
      (scenario as ViewMacroScenarioViewModel)?.inputs?.years?.target_year &&
      ((scenario as ViewMacroScenarioViewModel)?.inputs?.countries ?? [])
        .length > 0;
    if (allFieldsFilled) {
      setResultsHidden(false);
    } else if (!scenario) {
      setResultsHidden(true);
    }
  }, [scenario]);

  const handleUpdateScenario = async (
    scenarioUpdateModel: UpdateMacroScenarioViewModel,
  ) => {
    if (scenario?.id) {
      try {
        await updateMacroScenario({
          scenarioId: scenario.id,
          data: scenarioUpdateModel,
        });
      } catch (error) {
        showSnackbar(t("errorMessages.updateScenario"), "error");
      }
    }
  };

  useEffect(() => {
    if (updateMacroScenarioIsPending || scenarioIsPending) {
      showGlobalLoader(true);
    } else {
      showGlobalLoader(false);
    }
  }, [showGlobalLoader, updateMacroScenarioIsPending, scenarioIsPending]);

  // TODO: business would like adjustments disabled for now
  const handleAdjustInputs = (adjustment: Adjustment, editName: string) => {
    // const _scenarioToUpdate = deepClone(scenario);
    //     // if (editName === "new_adjustment") {
    //     //   _scenarioToUpdate.inputs.adjustments.push(adjustment);
    //     // } else {
    //     //   const index = _scenarioToUpdate.inputs.adjustments.findIndex(
    //     //     (item: { adjustment_name: string }) => item.adjustment_name === editName
    //     //   );
    //     //   _scenarioToUpdate.inputs.adjustments[index] = adjustment;
    //     // }
    //     // _scenarioToUpdate.id = scenarioId;
    //     // callUpdateScenarioAPI(_scenarioToUpdate);
    //     // handleNavigateToScenario(_scenarioToUpdate.id.toString());
  };

  // TODO: business would like adjustments disabled for now
  const handleDeleteAdjustment = (key: string) => {
    // const _scenarioToUpdate = deepClone(scenario);
    // if (_scenarioToUpdate?.inputs?.adjustments) {
    //   _scenarioToUpdate.inputs.adjustments =
    //     _scenarioToUpdate.inputs.adjustments.filter(
    //       (adjustment: Adjustment) => adjustment.adjustment_name !== key
    //     );
    // }
    // _scenarioToUpdate.id = scenarioId;
    // callUpdateScenarioAPI(_scenarioToUpdate);
    // handleNavigateToScenario(_scenarioToUpdate.id);
  };

  return (
    <>
      <Box>
        {!resultsHidden && scenario ? (
          <ManageExport
            open={open}
            setOpen={setOpen}
            scenario={scenario as ViewMacroScenarioViewModel}
          />
        ) : null}
        <Button
          variant="text"
          onClick={() =>
            navigate(redirectURL, {
              state: {
                headersIndent: headersIndent,
              },
            })
          }
          startIcon={<ArrowBackIosSharpIcon />}
        >
          {t("createScenarioSection.backToMacroHome")}
        </Button>
        <MacroPageHeroSection
          scenario={scenario as ViewMacroScenarioViewModel}
          onRenameScenario={handleRenameScenario}
          isEditScenario={!!scenarioId}
          handleRenameDescription={handleRenameDescription}
          headersIndent={headersIndent}
        />
        <DateAndCountryInputSection
          years={years?.map((year) => year.year) ?? []}
          baseYear={
            (scenario as ViewMacroScenarioViewModel)?.inputs?.years
              ?.base_year ?? null
          }
          targetYear={
            (scenario as ViewMacroScenarioViewModel)?.inputs?.years
              ?.target_year ?? null
          }
          countries={
            (scenario as ViewMacroScenarioViewModel)?.inputs?.countries ?? []
          }
          availableCountries={countries ?? []}
          handleUpdateScenario={handleUpdateScenario}
          disabled={false}
        />
        {resultsHidden ? (
          <Box className="macro-no-results-box">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={1}>
                <Typography className="header-H3">
                  {t("noResultsTitle")}
                </Typography>
              </Box>
              <Typography className="header-H4">
                {t("noResultsSubheading")}
              </Typography>
            </Box>
          </Box>
        ) : scenario ? (
          <ScenarioResults
            scenario={scenario as ViewMacroScenarioViewModel}
            setScenario={updateMacroScenario}
            disabled={
              !(
                !!(scenario as ViewMacroScenarioViewModel)?.inputs?.countries ||
                !!scenarioId
              )
            }
            handleAdjustInputs={handleAdjustInputs}
            handleDeleteAdjustment={handleDeleteAdjustment}
          />
        ) : null}
      </Box>
    </>
  );
}

export default MacroToolScenarioPage;
