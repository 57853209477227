import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import EmissionTimeChart from "./EmissionTimeChart";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { timeChartData, timeEvolutionTableData } from "../../types";
import {
  getTableData,
  getTimeEvolutionData,
  getTimeEvolutionLabels,
  getTitle,
  getUnitsToDisplay,
} from "./HelperService";
import { OptionalMetricsTable } from "../optional-metrics-table/OptionalMetricsTable";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";
import {
  TableResultsType,
  TableResultsFilterDataType,
} from "../scenario-results/results-tab-section/types";

type Props = {
  scenario: ViewMacroScenarioViewModel | null;
  sectionType: TableResultsType.BY_CATEGORY | TableResultsType.BY_PACK_TYPE;
};

function EmissionTimeSection({ scenario, sectionType }: Props) {
  const { t } = useTranslation("macro");
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [tableData, setTableData] = useState<timeEvolutionTableData | []>([]);
  const [chartData, setChartData] = useState<timeChartData | []>([]);
  const [units, setUnits] = useState<TableResultsFilterDataType>(
    TableResultsFilterDataType.ABSOLUTE
  );
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");

  const translations = useMemo(
    () => ({
      base: t("timeEvolutionLegend.base"),
      glide: t("timeEvolutionLegend.glide"),
      scenario: t("timeEvolutionLegend.scenario"),
    }),
    [t]
  );

  useEffect(() => {
    if (scenario?.results && sectionType) {
      setChartData(
        getTimeEvolutionData(
          scenario?.results,
          {
            base_year: scenario?.inputs?.years?.base_year!,
            target_year: scenario?.inputs?.years?.target_year!,
          },
          translations,
          units,
          sectionType
        )
      );
      setChartLabels(getTimeEvolutionLabels(scenario?.results?.[sectionType]));
      setTableData(getTableData(scenario?.results, units, sectionType));

      const title = getTitle(sectionType, units);
      setTitle(t(`${title}`));

      const unitsToDisplay = getUnitsToDisplay(units);
      setSubtitle(t(`${unitsToDisplay}`));
    }
  }, [sectionType, scenario, translations, units, t]);

  const handleChangeUnits = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value === TableResultsFilterDataType.ABSOLUTE) {
      setUnits(TableResultsFilterDataType.ABSOLUTE);
    } else {
      setUnits(TableResultsFilterDataType.PER_LITRE);
    }
  };

  const columns = [
    {
      accessorKey: "category", //simple recommended way to define a column
      header:
        sectionType === TableResultsType.BY_CATEGORY
          ? t("resultsSection.by_category")
          : t("resultsSection.by_pack_type"),
      id: "category",
    },
    {
      accessorKey: "base_to_glide_change", //simple recommended way to define a column
      header: t("resultsSection.timeEvolutionTable.baseGlidepath"),
      id: "base_to_glide_change",
    },
  ];

  return (
    <Box my={2} display={"flex"} flexDirection={"column"}>
      <Box alignSelf={"flex-end"}>
        <Typography className="header-H4">
          {t("dataVisualisationTabSection.timeEvolution.units.title")}
        </Typography>
        <ToggleButtonGroup
          value={units}
          exclusive
          onChange={handleChangeUnits}
          aria-label="text alignment"
          sx={{ marginTop: "12px" }}
        >
          <ToggleButton
            value={TableResultsFilterDataType.ABSOLUTE}
            aria-label={t(
              "dataVisualisationTabSection.timeEvolution.units.absolute"
            )}
            className="banner-config-toggle"
          >
            {t("dataVisualisationTabSection.timeEvolution.units.absolute")}
          </ToggleButton>
          <ToggleButton
            value={TableResultsFilterDataType.PER_LITRE}
            aria-label={t(
              "dataVisualisationTabSection.timeEvolution.units.perLitre"
            )}
            className="banner-config-toggle"
          >
            {t("dataVisualisationTabSection.timeEvolution.units.perLitre")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          textAlign: "start",
          display: "flex",
          flexDirection: "column",
        }}
        mb={2}
      >
        <Typography className="tableHeader">{title}</Typography>
        <Typography className="tableSubHeader">{subtitle}</Typography>
      </Box>
      <EmissionTimeChart labels={chartLabels} datasets={chartData} />
      <OptionalMetricsTable
        columns={columns}
        data={tableData}
        displaySubtitle={false}
        graphTable={false}
        title={t("resultsSection.timeEvolutionTable.title")}
      />
    </Box>
  );
}

export default EmissionTimeSection;
