import { Box, Button, Typography } from "@mui/material";
import { ToolConfigurationRoutesConfig } from "../../navigation/config";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SearchBar, useGlobalLoader } from "../../../../components/common";
import { useEffect, useMemo, useState } from "react";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import UserManagementTable, { PaginationState } from "./UserManagementTable/UserManagementTable";
import { useGetAllMembersMembersGet } from "../../../../orval/generated/endpoint";
import { debounce } from "lodash";
import { useModal } from "../../../../components/common/modal";
import { UpsertUserModal } from "./UpsertUserModal";
import Sort from "../../../../components/common/sort-dropdown/Sort";
import { SortConfig, SortValues } from "../../../../utils/SortConfig";
import { MemberSortOrder } from "../../../../orval/generated/models";
import { useSnackbar } from "../../../../components/common/notification/showSnackbar";

const defaultSortValue = SortValues.DATE_UPDATED_DESCENDING;

const UserManagementPage = () => {
  const { t } = useTranslation(["toolConfiguration", "common"]);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const { showGlobalLoader } = useGlobalLoader();
  const { openModal } = useModal();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 4, //customize the default page size
  });
  const [sortValue, setSortValue] = useState<SortValues>(defaultSortValue);
  const showSnackbar = useSnackbar();
  const getSortQuery = (value: SortValues): MemberSortOrder => {
    switch (value) {
      case SortValues.NAME_DESCENDING:
        return MemberSortOrder.name_desc;
      case SortValues.NAME_ASCENDING:
        return MemberSortOrder.name_asc;
      case SortValues.DATE_UPDATED_ASCENDING:
        return MemberSortOrder.updated_on_asc;
      default:
        return MemberSortOrder.updated_on_desc;
    }
  };

  const { data, isLoading: isPending, isError: errorFetchingMembers } = useGetAllMembersMembersGet({
    page: (pagination.pageIndex ?? 0) + 1,
    size: pagination.pageSize,
    search_string: searchString,
    sort_order: getSortQuery(sortValue),
  });

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchString(value);
        setPagination({ ...pagination, pageIndex: 0 });
      }, 300),
    [pagination],
  );

  useEffect(() => {
    showGlobalLoader(false);
    if (isPending) {
      showGlobalLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  const handleOpenCreateUserModal = () => {
    openModal(<UpsertUserModal isEditing={false} />);
  };

  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination(prev => ({ ...prev, pageIndex: 0 }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue])

  useEffect(() => {
    if (errorFetchingMembers) {
      showSnackbar("toolConfiguration:userManagementPage.membersFetchError", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFetchingMembers]);

  return (
    <Box>
      <Box>
        <Button
          variant="text"
          onClick={() =>
            navigate(`${ToolConfigurationRoutesConfig.toolConfigurationPage}?`)
          }
          startIcon={<ArrowBackIosSharpIcon />}
          sx={{ minWidth: "215px", marginRight: "auto" }}
        >
          {t("backButtonText")}
        </Button>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box minWidth={"350px"}>
            <Typography className={"header-H2"} fontWeight="500">
              {t("userManagementPage.title")}
            </Typography>
          </Box>

          <Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                handleOpenCreateUserModal();
              }}
              startIcon={<AddIcon />}
              sx={{ minWidth: "120px" }}
            >
              {t("userManagementPage.newUserButton")}
            </Button>
          </Box>
        </Box>
        <Box mt={4} display={"flex"} gap={2}>
          <SearchBar
            handleSearch={debouncedHandleSearch}
            placeHolder={t("userManagementPage.searchPlaceholder")}
          />
          <Sort<SortValues>
            sortValue={sortValue}
            setSortValue={setSortValue}
            values={SortConfig}
          />
        </Box>
      </Box>
      <Box mb={"80px"}>
        <UserManagementTable
          data={data}
          pagination={pagination}
          setPagination={setPagination}
          isLoading={isPending}
        ></UserManagementTable>
      </Box>
    </Box>
  );
};

export default UserManagementPage;
