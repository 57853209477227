import Box from "@mui/material/Box";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectDropdownListItem } from "components/common/select-dropdown/SelectDropdownRow";
import { useFormikContext } from "formik";
import { formikPropsType } from "../../routes/types";

export const MacroAdjustmentStepOne = () => {
  const { t } = useTranslation("macro");
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext<formikPropsType>();

  const [inputAreaDisabled, setInputAreaDisabled] = useState<boolean>(
    values.selectedInputArea === null || values.selectedInputArea === "",
  );
  const [adjustmentTypeDisabled, setAdjustmentTypeDisabled] = useState<boolean>(
    values.selectedAdjustmentType === "Not applicable" ||
    values.selectedAdjustmentType === null ||
    values.selectedAdjustmentType === "",
  );

  const [inputAreaDropdownItems, setInputAreaDropdownItems] = useState<
    SelectDropdownListItem<any>[]
  >([]);

  const [adjustmentDropdownItems, setAdjustmentDropdownItems] = useState<
    SelectDropdownListItem<any>[]
  >([]);

  useEffect(() => {
    filterInputAreas(values.selectedPillar);
  }, [values.selectedPillar]);

  useEffect(() => {
    if (typeof values.selectedInputArea === "string") {
      setupAdjustmentTypeDropdown(values.selectedInputArea);
    }
  }, [values.selectedInputArea]);

  const pillars: SelectDropdownListItem<any>[] = [
    { title: "Ingredients", value: "Ingredients" },
    { title: "Packaging", value: "Packaging" },
    { title: "Manufacturing", value: "Manufacturing" },
    { title: "Logistics", value: "Logistics" },
    { title: "CDE", value: "CDE" },
    { title: "Sales mix", value: "Sales mix" },
  ];

  const filterInputAreas = (selectedPillar: string | null | undefined) => {
    switch (selectedPillar) {
      case "Ingredients":
        setInputAreaDropdownItems([
          {
            title: "Ingredient weights",
            value: [
              {
                title: "Multi brand adjustment",
                value: "Multi brand adjustment",
              },
              {
                title: "Specific brand adjustment",
                value: "Specific brand adjustment",
              },
            ],
          },
          {
            title: "Ingredient emission factors",
            value: "Ingredient emission factors",
          },
        ]);
        setInputAreaDisabled(false);
        break;
      case "Packaging":
        setInputAreaDropdownItems([
          {
            title: "Packaging weights",
            value: [
              {
                title: "Multi SKU adjustment",
                value: "Multi SKU adjustment",
              },
              {
                title: "Specific SKU adjustment",
                value: "Specific SKU adjustment",
              },
            ],
          },
          {
            title: "Recycled content",
            value: "Recycled content",
          },
          {
            title: "Recovery rate",
            value: "Recovery rate",
          },
          {
            title: "Net loss rate",
            value: "Net loss rate",
          },
          {
            title: "Packaging emission factors",
            value: "Packaging emission factors",
          },
        ]);
        setInputAreaDisabled(false);
        break;
      case "Manufacturing":
        setInputAreaDropdownItems([
          {
            title: "Energy usage ratio (EUR)",
            value: "Energy usage ratio (EUR)",
          },
          {
            title: "Source mix and emission factors",
            value: "Source mix and emission factors",
          },
        ]);
        setInputAreaDisabled(false);
        break;
      case "Logistics":
        setInputAreaDropdownItems([
          {
            title: "Distance travelled",
            value: "Distance travelled",
          },
          {
            title: "Road vehicle mix and emission factors",
            value: "Road vehicle mix and emission factors",
          },
          {
            title: "Other vehicle mix and emission factors",
            value: "Other vehicle mix and emission factors",
          },
        ]);
        setInputAreaDisabled(false);
        break;
      case "CDE":
        setInputAreaDropdownItems([
          {
            title: "CDE fleet breakdown",
            value: "CDE fleet breakdown",
          },
          {
            title: "CDE energy mix and emission factors",
            value: "CDE energy mix and emission factors",
          },
        ]);
        setInputAreaDisabled(false);
        break;
      case "Sales mix":
        setInputAreaDropdownItems([
          { title: "Sales growth (CAGR)", value: "Sales growth (CAGR)" },
          { title: "Category mix", value: "Category mix" },
          { title: "Pack mix", value: "Pack mix" },
          { title: "DLZ share", value: "DLZ share" },
        ]);
        setInputAreaDisabled(false);
        break;
      default:
        return [];
    }
  };

  const handlePillarSelection = (newValue: string | null) => {
    setFieldValue("selectedPillar", newValue);
    setFieldValue("inputArea", null);
    setFieldValue("selectedInputArea", null);
    setFieldValue("selectedAdjustmentType", null);

    setInputAreaDisabled(true);
    setInputAreaDropdownItems([]);

    setAdjustmentTypeDisabled(true);
    setAdjustmentDropdownItems([]);

    if (newValue) {
      filterInputAreas(newValue);
    }
  };

  const handleInputAreaSelection = (value: string | null) => {
    setFieldValue("selectedInputArea", value);
    setFieldValue("selectedAdjustmentType", null);
    if (typeof value === "string") {
      setupAdjustmentTypeDropdown(value);
    }
    switch (value) {
      case "Ingredient weights":
      case "Packaging weights":
        setAdjustmentTypeDisabled(false);
        setFieldValue("inputArea", "");
        break;
      default:
        setAdjustmentTypeDisabled(true);
        setFieldValue("inputArea", value);
        setFieldValue("selectedAdjustmentType", "Not applicable");
    }
  };

  const handleAdjustmentTypeSelection = (value: string | null) => {
    setFieldValue("inputArea", value);
    setFieldValue("selectedAdjustmentType", value);
  };

  const setupAdjustmentTypeDropdown = (inputArea: string) => {
    switch (inputArea) {
      case "Ingredient weights":
        setAdjustmentDropdownItems([
          {
            title: "Multi brand adjustment",
            value: "Multi brand adjustment",
          },
          {
            title: "Specific brand adjustment",
            value: "Specific brand adjustment",
          },
        ]);
        break;
      case "Packaging weights":
        setAdjustmentDropdownItems([
          {
            title: "Multi SKU adjustment",
            value: "Multi SKU adjustment",
          },
          {
            title: "Specific SKU adjustment",
            value: "Specific SKU adjustment",
          },
        ]);
        break;
      default:
        setAdjustmentDropdownItems([
          {
            title: "Not applicable",
            value: "Not applicable",
          },
        ]);
    }
  };

  return (
    <Box>
      <Box mb={1}>
        <Typography className="header-H2 text-ellipsis">
          {t("adjustmentsPage.wizard.stepOne.title")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepOne.description")}
        </Typography>
      </Box>
      <Box mb={2}>
        <TextField
          variant={"outlined"}
          size={"small"}
          name="name"
          label={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameInputTitle",
          )}
          placeholder={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameInputPlaceholder",
          )}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          data-testid={"adjustment-name-input"}
        />
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          name={"description"}
          label={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameDescriptionInputTitle",
          )}
          placeholder={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameDescriptionInputPlaceholder",
          )}
          multiline
          rows={3}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid={"adjustment-description-input"}
        />
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepOne.descriptionTwo")}
        </Typography>
      </Box>
      <Box display={"flex"}>
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("macro:adjustmentsPage.wizard.stepOne.pillarSelection")}
              name={"pillar"}
            />
          )}
          value={values.selectedPillar}
          options={pillars.map((pillar) => pillar.title)}
          size={"small"}
          sx={{ marginRight: "1rem", width: 300 }}
          onChange={(event: any, newValue: string | null | undefined) => {
            if (newValue === undefined) {
              return;
            }
            handlePillarSelection(newValue);
          }}
          clearOnBlur
          handleHomeEndKeys
          data-testid={"pillar-selection"}
        />
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                "macro:adjustmentsPage.wizard.stepOne.inputAreaSelection",
              )}
              name={"inputArea"}
            />
          )}
          value={values.selectedInputArea}
          options={inputAreaDropdownItems.map((item) => item.title)}
          size={"small"}
          sx={{ marginRight: "1rem", width: 300 }}
          onChange={(event, value) => {
            if (value === undefined) {
              return;
            }
            handleInputAreaSelection(value);
          }}
          clearOnBlur
          handleHomeEndKeys
          data-testid={"input-area-selection"}
          disabled={inputAreaDisabled}
        />
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                "macro:adjustmentsPage.wizard.stepOne.adjustmentTypeSelection",
              )}
              name={"adjustmentType"}
            />
          )}
          value={values.selectedAdjustmentType}
          options={adjustmentDropdownItems.map((item) => item.title)}
          size={"small"}
          sx={{ marginRight: "1rem", width: 300 }}
          onChange={(event, value) => {
            if (value === undefined) {
              return;
            }
            handleAdjustmentTypeSelection(value);
          }}
          clearOnBlur
          handleHomeEndKeys
          data-testid={"adjustment-type-selection"}
          disabled={adjustmentTypeDisabled}
        />
      </Box>
    </Box>
  );
};
