import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useGlobalLoader } from "../../../components/common";
import { getEnvironmentVariableValue } from "../../../utils";
import { EnvironmentVariable } from "../../../constants";
import ScenarioList from "../components/scenario-list/ScenarioList";
import { useState } from "react";
import { ViewMacroScenarioViewModel } from "../../../orval/generated/models";
import { useSnackbar } from "../../../components/common/notification/showSnackbar";
import { useNavigate } from "react-router-dom";
import { useCreateMacroScenarioScenariosMacroPost } from "../../../orval/generated/endpoint";

const sandboxMacroScenarioIdKey = "sandbox_macro_scenario_id";

function MacroToolPage() {
  const { t } = useTranslation("macro");
  const [sandboxMacroScenarioID, setSandboxMacroScenarioID] = useState<
    string | null
  >(null);
  const [chosenScenarios, setChosenScenarios] = useState<string[]>([]);
  const [chosenBaseYears, setChosenBaseYears] = useState<number[]>([]);
  const hideFeature = getEnvironmentVariableValue(
    EnvironmentVariable.featureToggle.hideMacroTool,
  );
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const { showGlobalLoader } = useGlobalLoader();

  const { mutate: createMacroScenario, isPending: createMacroIsPending } =
    useCreateMacroScenarioScenariosMacroPost({
      mutation: {
        onSuccess: (macroScenario: ViewMacroScenarioViewModel) => {
          navigate(`/country-level-analytics/scenario/${macroScenario.id}`, {
            state: { id: macroScenario.id },
          });
        },
        onError: (createMacroError: unknown) => {
          showSnackbar("micro:errorMessages.createScenario", "error");
          console.error("Macro create scenario error : ", createMacroError);
        },
        onSettled: () => {
          showGlobalLoader(createMacroIsPending);
        },
      },
    });

  return (
    <>
      <Box display="flex">
        <Box>
          <Stack
            direction={{ sm: "column", md: "row" }}
            justifyContent={{ sm: "center", md: "space-between" }}
            alignItems={{ sm: "flex-start", md: "center" }}
            spacing={2}
          >
            <Box>
              <Typography className={"header-H2"} fontWeight="500">
                {t("macroToolTitle")}
              </Typography>
            </Box>
            <Box>
              <Stack direction={"row"} spacing={2}>
                {/*  TODO: business would like compare disabled for now*/}
                {/*<Tooltip*/}
                {/*  // title={t("compareScenariosTooltipInfo")}*/}
                {/*  title=""*/}
                {/*  arrow*/}
                {/*  placement="top"*/}
                {/*>*/}
                {/*  <span>*/}
                {/*    <Button*/}
                {/*      // disabled={chosenScenarios.length < 2}*/}
                {/*      variant="outlined"*/}
                {/*      color="primary"*/}
                {/*      onClick={redirect}*/}
                {/*      // TODO: business would like compare disabled for now*/}
                {/*      disabled*/}
                {/*    >*/}
                {/*      {t("buttonLabel.compareScenarios")}*/}
                {/*    </Button>{" "}*/}
                {/*  </span>*/}
                {/*</Tooltip>*/}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => createMacroScenario()}
                >
                  {t("buttonLabel.createScenario")}
                </Button>
              </Stack>
            </Box>
          </Stack>

          <Box py={2}>
            <Typography>{t("macroToolDescription")}</Typography>
          </Box>

          <ScenarioList
            setSandboxMacroScenarioID={setSandboxMacroScenarioID}
            sandboxMacroScenarioIdKey={sandboxMacroScenarioIdKey}
            sandboxMacroScenarioID={sandboxMacroScenarioID}
            chosenScenarios={chosenScenarios}
            setChosenScenarios={setChosenScenarios}
            chosenBaseYears={chosenBaseYears}
            setChosenBaseYears={setChosenBaseYears}
          />
        </Box>
      </Box>
    </>
  );
}

export default MacroToolPage;
