import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AbsoluteCarbonEmissions,
  CarbonEmissionsOverview,
  PerLitreCarbonEmissions,
  TimeEvolution,
} from "./results-section";
import { Tablist } from "../../../../components/common";
import { useTranslation } from "react-i18next";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";
import { OptionalMetricsTableResultsType } from "../optional-metrics-table/HelperService";

type ResultsProps = {
  disabled: boolean;
  type: OptionalMetricsTableResultsType;
  tabList: Tablist[];
  scenarios: ViewMacroScenarioViewModel[] | null;
};
function CompareScenarioResultsSection({
  disabled,
  tabList,
  type,
  scenarios,
}: ResultsProps) {
  const { t } = useTranslation("macro");
  return (
    <Accordion disabled={disabled}>
      <AccordionSummary className="no-border" expandIcon={<ExpandMoreIcon />}>
        {/*<Typography className="header-H4-bold">*/}
        {/*  {type === "category"*/}
        {/*    ? t("compareScenarioSection.headersSection.resultsByCategory.title")*/}
        {/*    : t("compareScenarioSection.headersSection.resultsByPack.title")}*/}
        {/*</Typography>*/}
      </AccordionSummary>
      <AccordionDetails>
        <CarbonEmissionsOverview scenarios={scenarios} type={type} />
        <AbsoluteCarbonEmissions
          scenarios={scenarios}
          tabList={tabList}
          type={type}
        />
        <PerLitreCarbonEmissions
          scenarios={scenarios}
          tabList={tabList}
          type={type}
        />
        <TimeEvolution scenarios={scenarios} tabList={tabList} type={type} />
      </AccordionDetails>
    </Accordion>
  );
}

export default CompareScenarioResultsSection;
