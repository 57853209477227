import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import {
  CreateMemberModel,
  CountryViewModel,
  MemberDetails,
  FunctionalAreaItemViewModel,
  PermissionItem,
  AccordionsViewModel,
} from "../../../../orval/generated/models";
import { useGlobalLoader } from "../../../../components/common";
import { useSnackbar } from "../../../../components/common/notification/showSnackbar";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useModal } from "../../../../components/common/modal";
import { SelectDropdownListItem } from "../../../../components/common/select-dropdown/SelectDropdownRow";

import SelectDropdown from "../../../../components/common/select-dropdown/SelectDropdown";
import {
  getGetAllMembersMembersGetQueryKey,
  useCreateMemberMembersPost,
  useEditMemberByIdMembersMemberIdPatch,
  useGetAllAccordionsAccordionsGet,
  useGetAllFunctionalAreasUserManagementFunctionalAreasGet,
  useGetGumTerritoriesTerritoriesGet,
  useGetMemberCountriesMembersCountriesGet,
} from "../../../../orval/generated/endpoint";

type UserInfoField = {
  name: keyof CreateMemberModel;
  label: string;
  placeholder: string;
};

type AccessField = {
  title: string;
  formikLabel: string;
  helperText: string;
  listItems: SelectDropdownListItem<string>[] | string[];
  savedSelectedItems: string[];
  onSave: (selectedItems: string[], label?: string) => void;
  onOpen: () => void;
};

interface UpsertUserModalProps {
  isEditing: boolean;
  userInformation?: MemberDetails;
}

export const UpsertUserModal = (props: UpsertUserModalProps) => {
  const { t } = useTranslation("toolConfiguration");
  const { showGlobalLoader } = useGlobalLoader();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const modal = useModal();

  const {
    data: countryData,
    isPending: countriesIsPending,
    isError: errorFetchingCountries,
  } = useGetMemberCountriesMembersCountriesGet();

  const {
    data: territoryData,
    isPending: territoryDataIsPending,
    isError: errorFetchingTerritoryData,
  } = useGetGumTerritoriesTerritoriesGet();

  const {
    data: functionalAccessData,
    isPending: functionalAccessDataIsPending,
    isError: errorFetchingFunctionalAccessData,
  } = useGetAllFunctionalAreasUserManagementFunctionalAreasGet();

  const {
    data: adjustmentAccessData,
    isPending: adjustmentAccessDataIsPending,
    isError: errorFetchingAdjustmentAccessData,
  } = useGetAllAccordionsAccordionsGet();

  const flattenAccess = (
    data: any[],
  ): any[] => {
    return data.flatMap((item: any) => {
      if (item.subItem) {
        return flattenAccess(item.subItem);
      } else {
        return { id: item.id, name: item.name };
      }
    });
  };

  const getFunctionalAccessItems = (
    data: FunctionalAreaItemViewModel[],
  ): SelectDropdownListItem<string>[] => {
    return data.map(
      (item: FunctionalAreaItemViewModel): SelectDropdownListItem<string> => {
        if (item.subItem) {
          return {
            title: item.name,
            value: getFunctionalAccessItems(item.subItem),
          };
        } else {
          return {
            value: item.name,
          };
        }
      },
    );
  };

  const getAdjustmentAccessItems = (
    data: AccordionsViewModel[],
  ): SelectDropdownListItem<string>[] => {
    return data.map(
      (item: AccordionsViewModel): SelectDropdownListItem<string> => {
        if (item.subItem) {
          return {
            title: item.name,
            value: getAdjustmentAccessItems(item.subItem),
          };
        } else {
          return {
            value: item.name,
          };
        }
      },
    ).filter(access => access.value !== "Serving Size");
  };

  const flattenedFunctionalAccess = flattenAccess(functionalAccessData || []);
  const functionalAccessItems = getFunctionalAccessItems(
    functionalAccessData || [],
  );

  const flattenedAdjustmentAccess = flattenAccess(adjustmentAccessData || []);
  const adjustmentAccessItems = getAdjustmentAccessItems(
    adjustmentAccessData || [],
  );

  const { mutateAsync: createUser, isPending: createUserIsPending } =
    useCreateMemberMembersPost({
      mutation: {
        onSuccess: () => {
          showSnackbar(
            "toolConfiguration:userManagementPage.upsertUser.createUser.successMessage",
            "success",
          );
          queryClient.invalidateQueries({
            queryKey: getGetAllMembersMembersGetQueryKey(),
          });
        },
      },
    });
  const handleCreateUser = async (values: CreateMemberModel) => {
    try {
      await createUser({ data: values });
    } catch (error) {
      showSnackbar(error as AxiosError<unknown, any>, "error");
    } finally {
      modal.closeModal();
      showGlobalLoader(false);
    }
  };
  const { mutateAsync: editUser, isPending: editUserIsPending } =
    useEditMemberByIdMembersMemberIdPatch({
      mutation: {
        onSuccess: () => {
          showSnackbar(
            "toolConfiguration:userManagementPage.upsertUser.editUser.successMessage",
            "success",
          );
          queryClient.invalidateQueries({
            queryKey: getGetAllMembersMembersGetQueryKey(),
          });
        },
      },
    });

  const handleEditUser = async (values: CreateMemberModel) => {
    try {
      await editUser({
        memberId: props.userInformation?.id ?? 0,
        data: values,
      });
    } catch (error) {
      showSnackbar(error as AxiosError<unknown, any>, "error");
    } finally {
      modal.closeModal();
      showGlobalLoader(false);
    }
  };

  useEffect(() => {
    countriesIsPending ||
      functionalAccessDataIsPending ||
      adjustmentAccessDataIsPending ||
      createUserIsPending ||
      editUserIsPending ||
      territoryDataIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [editUserIsPending, createUserIsPending, countriesIsPending, showGlobalLoader, functionalAccessDataIsPending, territoryDataIsPending, adjustmentAccessDataIsPending]);

  useEffect(() => {
    errorFetchingCountries &&
      showSnackbar(
        "toolConfiguration:userManagementPage.upsertUser.countryFetchError",
        "error",
      );
  }, [errorFetchingCountries, showSnackbar]);

  useEffect(() => {
    errorFetchingTerritoryData &&
      showSnackbar(
        "toolConfiguration:userManagementPage.upsertUser.territoryFetchError",
        "error",
      );
  }, [errorFetchingTerritoryData, showSnackbar]);

  useEffect(() => {
    errorFetchingAdjustmentAccessData &&
      showSnackbar(
        "toolConfiguration:userManagementPage.upsertUser.adjustmentAccessFetchError",
        "error",
      );
  }, [errorFetchingAdjustmentAccessData, showSnackbar]);

  useEffect(() => {
    errorFetchingFunctionalAccessData &&
      showSnackbar(
        "toolConfiguration:userManagementPage.upsertUser.functionalAccessFetchError",
        "error",
      );
  }, [errorFetchingFunctionalAccessData, showSnackbar]);

  const userValidationSchema = yup.object({
    first_name: yup
      .string()
      .min(2, t("userManagementPage.upsertUser.userValidation.firstNameMin"))
      .required(
        t("userManagementPage.upsertUser.userValidation.firstNameRequired"),
      ),
    last_name: yup
      .string()
      .min(2, t("userManagementPage.upsertUser.userValidation.lastNameMin"))
      .required(
        t("userManagementPage.upsertUser.userValidation.lastNameRequired"),
      ),
    email: yup
      .string()
      .email(t("userManagementPage.upsertUser.userValidation.emailInvalid"))
      .required(
        t("userManagementPage.upsertUser.userValidation.emailRequired"),
      ),
    organisation: yup
      .string()
      .required(
        t("userManagementPage.upsertUser.userValidation.organisationRequired"),
      ),
    territory: yup
      .string()
      .required(
        t("userManagementPage.upsertUser.userValidation.territoryRequired"),
      ),
    functional_access: yup
      .array()
      .min(
        1,
        t(
          "userManagementPage.upsertUser.userValidation.functionalAccessRequired",
        ),
      )
      .required(
        t(
          "userManagementPage.upsertUser.userValidation.functionalAccessRequired",
        ),
      ),
    countries: yup
      .array()
      .min(1, t("userManagementPage.upsertUser.userValidation.countryRequired"))
      .required(
        t("userManagementPage.upsertUser.userValidation.countryRequired"),
      ),
    adjustment_access: yup
      .array()
      .min(1, t("userManagementPage.upsertUser.userValidation.adjustmentAccessRequired"))
      .required(
        t("userManagementPage.upsertUser.userValidation.adjustmentAccessRequired"),
      ),
  });
  const formik = useFormik<CreateMemberModel>({
    initialValues: {
      countries:
        props.userInformation?.permissions?.countries?.map(
          (country) => country.country,
        ) || [],
      functional_access:
        props.userInformation?.permissions?.functional_access?.map(
          (access) => access.id,
        ) || [],
      adjustment_access: (!props.userInformation?.permissions?.adjustment_access?.map(
        (access) => access.id,
      )?.length ? [1] : props.userInformation?.permissions?.adjustment_access?.map(
        (access) => access.id,
      )) || [1],
      first_name: props.userInformation?.first_name ?? "",
      last_name: props.userInformation?.last_name ?? "",
      email: props.userInformation?.email ?? "",
      organisation: props.userInformation?.organisation ?? "",
      territory: props.userInformation?.territory ?? "",
    },
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      values.email = values.email.toLowerCase();
      if (props.isEditing) {
        handleEditUser(values);
      } else {
        handleCreateUser(values);
      }
    },
  });

  const userInfoFields: UserInfoField[] = [
    {
      name: "first_name",
      label: "First Name",
      placeholder: "userManagementPage.upsertUser.firstNamePlaceholder",
    },
    {
      name: "last_name",
      label: "Last Name",
      placeholder: "userManagementPage.upsertUser.lastNamePlaceholder",
    },
    {
      name: "email",
      label: "Email",
      placeholder: "userManagementPage.upsertUser.emailPlaceholder",
    },
    {
      name: "organisation",
      label: "Organisation",
      placeholder: "userManagementPage.upsertUser.organisationPlaceholder",
    },
  ];

  const handleFunctionalAccessChange = useCallback(
    (selectedItems: string[], label: string) => {
      formik.setFieldValue(
        label,
        selectedItems.map(
          (item) =>
            (flattenedFunctionalAccess as PermissionItem[]).find(
              (access: PermissionItem) => access.name === item,
            )?.id,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flattenedFunctionalAccess],
  );
  const handleCountryAccessChange = useCallback(
    (selectedItems: string[], label: string) => {
      formik.setFieldValue(
        label,
        selectedItems.map(
          (item) =>
            countryData?.find(
              (country: CountryViewModel) => country.display_name === item,
            )?.country,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryData],
  );

  const handleAdjustmentAccessChange = useCallback(
    (selectedItems: string[], label: string) => {
      let ids: number[] = selectedItems.map(
        (item) =>
          (flattenedAdjustmentAccess as PermissionItem[]).find(
            (access: PermissionItem) => access.name === item,
          )?.id as number,
      )

      if (!ids.find(id => id === 1)) ids.push(1)

      formik.setFieldValue(
        label,
        ids
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adjustmentAccessData],
  );

  const accessFields: AccessField[] = useMemo(
    () => [
      {
        title: "Country Access",
        formikLabel: "countries",
        helperText: formik.touched.countries
          ? (formik.errors.countries as string)
          : "",
        listItems:
          countryData?.map(
            (country: CountryViewModel) => country.display_name,
          ) ?? [],

        savedSelectedItems:
          formik.values.countries.map(
            (code: string) =>
              countryData?.find(
                (country: CountryViewModel) => country.country === code,
              )?.display_name || "",
          ) || [],
        onSave: (selectedItem: string[], label?: string) => {
          if (label) {
            handleCountryAccessChange(selectedItem, label as string);
          }
        },
        onOpen: () => formik.setFieldTouched("countries", true),
      },
      {
        title: t("userManagementPage.upsertUser.adjustmentAccessLabel"),
        formikLabel: "adjustment_access",
        helperText: formik.touched.adjustment_access
          ? (formik.errors.adjustment_access as string)
          : "",
        listItems: adjustmentAccessItems,
        savedSelectedItems: formik.values.adjustment_access.map(
          (id: number) =>
            flattenedAdjustmentAccess?.find(
              (access: AccordionsViewModel) => access.id === id,
            )?.name || "",
        ).filter(access => access !== "Serving Size"),
        onSave: (selectedItem: string[], label?: string) => {
          if (label) {
            handleAdjustmentAccessChange(selectedItem, label);
          }
        },
        onOpen: () => formik.setFieldTouched("adjustment_access", true),
      },
      {
        title: "Functional Access",
        formikLabel: "functional_access",
        helperText: formik.touched.functional_access
          ? (formik.errors.functional_access as string)
          : "",
        listItems: functionalAccessItems,
        savedSelectedItems: formik.values.functional_access.map(
          (id: number) =>
            flattenedFunctionalAccess?.find(
              (access: FunctionalAreaItemViewModel) => access.id === id,
            )?.name || "",
        ),
        onSave: (selectedItem: string[], label?: string) => {
          if (label) {
            handleFunctionalAccessChange(selectedItem, label);
          }
        },
        onOpen: () => formik.setFieldTouched("functional_access", true),
      }

    ],
    [adjustmentAccessItems, countryData, flattenedAdjustmentAccess, flattenedFunctionalAccess, formik, functionalAccessItems, handleAdjustmentAccessChange, handleCountryAccessChange, handleFunctionalAccessChange, t],
  );
  useEffect(() => {
    if (!props.isEditing) {
      if (formik.values.email.toLowerCase().includes("@pwc")) {
        if (!formik.touched.countries) {
          formik.setFieldValue(
            "countries",
            countryData?.map((country: CountryViewModel) => country.country),
          );
        }

        if (!formik.touched.functional_access) {
          formik.setFieldValue(
            "functional_access",
            flattenedFunctionalAccess?.map(
              (access: FunctionalAreaItemViewModel) => access.id,
            ),
          );
        }

        if (!formik.touched.adjustment_access) {

          formik.setFieldValue(
            "adjustment_access",
            flattenedAdjustmentAccess?.map(
              (access: AccordionsViewModel) => access.id,
            ));
        }
      } else {
        if (!formik.touched.countries) {
          formik.setFieldValue("countries", []);
        }

        if (!formik.touched.functional_access) {
          formik.setFieldValue("functional_access", []);
        }

        if (!formik.touched.adjustment_access) {
          formik.setFieldValue("adjustment_access", []);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.email,
    formik.touched.functional_access,
    formik.touched.adjustment_access,
    formik.touched.countries,
    props.isEditing,
  ]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1 }}
          onClick={modal.closeModal}
        >
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box mb={1}>
        <Typography textAlign="center" variant="h3" fontWeight="bold" mb={4}>
          {props.isEditing
            ? t("userManagementPage.upsertUser.editUser.title")
            : t("userManagementPage.upsertUser.createUser.title")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          width={"400px"}
          mx={9}
          mb={3}
        >
          <Box>
            <Typography variant="h6" mb={2}>
              {t("userManagementPage.upsertUser.userInfoSubheading")}
            </Typography>
            {userInfoFields.map((field: UserInfoField) => (
              <TextField
                fullWidth
                name={field.name}
                label={field.label}
                placeholder={t(field.placeholder)}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[field.name] &&
                  Boolean(formik.errors[field.name])
                }
                disabled={field.name === "email" && props.isEditing}
                helperText={
                  formik.touched[field.name] && formik.errors[field.name]
                }
                required
                key={`${field.name}`}
                sx={{ marginBottom: 2, height: "5em" }}
              />
            ))}
            {territoryData && (
              <Autocomplete
                disablePortal
                disableClearable
                options={[{ country_code: "", name: "" }, ...territoryData]}
                filterOptions={(options, state) =>
                  state.inputValue.trim() ? options.filter(option => option.name !== "" && option.name.toLocaleLowerCase().includes(state.inputValue.trim().toLocaleLowerCase())) : options.filter(option => option.name !== "")
                }
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.country_code === value.country_code}
                fullWidth
                value={territoryData.find(country => country.country_code === formik.values.territory) || { country_code: "", name: "" }}
                onChange={(_e, value) => formik.setFieldValue("territory", value?.country_code)}
                renderInput={(params) => <TextField {...params} label="Territory" name="territory" onBlur={formik.handleBlur} required error={
                  formik.touched.territory &&
                  Boolean(formik.errors.territory)
                }
                  helperText={formik.touched.territory &&
                    Boolean(formik.errors.territory) && formik.errors.territory} />}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px #CBCBCB solid",
              marginTop: 2,
            }}
          ></Box>
          <Box>
            <Typography variant="h6" mb={2}>
              {t("userManagementPage.upsertUser.permissionsSubheading")}
            </Typography>
            {accessFields.map((field: AccessField) => (
              <FormControl
                required
                fullWidth
                sx={{ marginBottom: 2, height: "5em" }}
                key={field.title}
              >
                <SelectDropdown
                  listItems={field.listItems}
                  onSave={field.onSave}
                  savedSelectedItems={field.savedSelectedItems}
                  title={field.title}
                  requireSave={false}
                  showSearchBar
                  selectAll
                  formikLabel={field.formikLabel}
                  helperText={field.helperText}
                  onOpen={field.onOpen}
                ></SelectDropdown>
              </FormControl>
            ))}
          </Box>
          <Stack direction="row" spacing={2} margin="auto" marginTop={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => modal.closeModal()}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
            >
              {t("common:actions.save")}
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};
