import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { CustomTabs, Tablist } from "../../../../../components/common";
// import CarbonEmissionByPillar from "../../carbon-emission-by-pillar/CarbonEmissionByPillar";
import { ViewMacroScenarioViewModel } from "../../../../../orval/generated/models";
import { OptionalMetricsTableResultsType } from "../../optional-metrics-table/HelperService";

type Props = {
  tabList: Tablist[];
  type: OptionalMetricsTableResultsType;
  scenarios: ViewMacroScenarioViewModel[] | null;
};

export function AbsoluteCarbonEmissions({ tabList, type, scenarios }: Props) {
  const { t } = useTranslation("macro");
  const [scenario, setSenario] = useState<ViewMacroScenarioViewModel | null>();
  const [value, setValue] = useState<number>(0);
  const metrics = ["base", "glide", "scenario"];

  useEffect(() => {
    if (scenarios) {
      setSenario(scenarios[value]);
    }
  }, [value, scenarios]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="header-H4">
          {t("resultsSection.absoluteCarbonEmissionsByPillarSection.title")}{" "}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTabs
          removeTranslate={true}
          tabList={tabList}
          value={value}
          setValue={setValue}
        />

        {/* <Box gap="32px" justifyContent="space-between">
          {metrics.map((metric: string) => (
            <CarbonEmissionByPillar
              case_="absolute_by_pillar"
              scenario={scenario}
              toRender={type}
              key={metric}
              type={metric}
            />
          ))}
        </Box> */}
      </AccordionDetails>
    </Accordion>
  );
}
