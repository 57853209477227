import i18next from "i18next";
import { ProductMetrics } from "./TableConfig";
import { ProductColumnData } from "../../types";
import { ProductMetricsType, ProductTableData } from "../ProdutsTable/types";
import { Product } from "../../../../orval/generated/models";
import { formatNumber } from "../../../../utils/formatNumber/formatNumber";
import { ProductConfigurationsMicro } from "../ProductsEditableTable";

function getProductsTableData(
  products: ProductColumnData[],
  items: Product[],
  stringify?: boolean,
): ProductTableData {
  const data: Array<any> = [];

  ProductMetrics.forEach((config: ProductMetricsType) => {
    const dataObj: any = {
      metric: i18next.t(config.label),
    };

    products.forEach((product: ProductColumnData) => {
      const productData = items.find((pr) => pr.guid === product.guid);
      let value = productData?.[config.key as keyof Product];

      if (config.key === "pack_size" && typeof value === "number") {
        value = formatNumber(value, ProductConfigurationsMicro.SERVING_SIZE);
      }

      dataObj[`Product ${productData?.guid}` || ""] =
        stringify && value
          ? `${value} ${config.key === "pack_size" ? " ml" : ""}`
          : `${value}${config.key === "pack_size" ? " ml" : ""}`;
    });
    data.push(dataObj);
  });
  return data;
}

export { getProductsTableData };
