import React, { useState } from "react";
import { Box } from "@mui/material";
import { MacroAdjustmentStepOne } from "../components/macro-adjustment-wizard/MacroAdjustmentStepOne";
import { MacroAdjustmentStepTwo } from "../components/macro-adjustment-wizard/MacroAdjustmentStepTwo";
import { MacroAdjustmentStepThree } from "../components/macro-adjustment-wizard/MacroAdjustmentStepThree";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MacroToolRoutesConfig } from "../navigation/config";
import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";
import ConfirmationModal from "../../../components/common/ConfirmationModal/ConfirmationModal";
import { useModal } from "components/common/modal/ModalContext";
import _ from "lodash";
import MacroAdjustmentsWizardHeaderAndStepper from "../components/macro-adjustements-wizard-header-and-stepper/MacroAdjustmentsWizardHeaderAndStepper";
import MacroAdjustmentsWizardFooter from "../components/macro-adjustments-wizard-footer/MacroAdjustmentsWizardFooter";
import { formikPropsType, MacroAdjustmentStep } from "./types";

const MacroAdjustmentWizard = () => {
  const { t } = useTranslation(["macro", "common"]);
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const scenarioId = urlScenarioId || location.state?.id;
  const [activeStep, setActiveStep] = useState(0);
  const [headersIndent, setHeadersIndent] = useState<string>(
    location.state?.headersIndent || "0px",
  );
  const leftButtonDisabled = activeStep === 0;

  const getStepTitle = (
    stepIndex: number,
    formikProps: FormikProps<formikPropsType>,
  ) => {
    if (stepIndex === 0) {
      return t("macro:adjustmentsPage.wizard.stepOneTitle");
    } else if (stepIndex === 1 || stepIndex === 2) {
      return (
        formikProps.values.name ||
        t("macro:adjustmentsPage.wizard.stepOneTitle")
      );
    }
  };

  const getStepSubtitle = (formikProps: FormikProps<formikPropsType>) => {
    return (
      formikProps.values.inputArea ||
      t("macro:adjustmentsPage.wizard.stepOneTitle")
    );
  };

  const handleRightButtonClick = (
    formikProps: FormikProps<formikPropsType>,
  ) => {
    if (activeStep < steps(formikProps).length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleFinish(formikProps.values);
    }
  };

  const handleLeftButtonClick = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = (values: formikPropsType) => {
    navigateToAdjustments();
  };

  const macroAdjustmentValidationSchema = yup.object({
    name: yup
      .string()
      .required(
        t("macro:adjustmentsPage.wizard.validationErrors.adjustmentName"),
      ),
    description: yup.string(),
    inputArea: yup
      .string()
      .required(t("macro:adjustmentsPage.wizard.validationErrors.inputArea")),
  });

  const steps = (
    formikProps: FormikProps<formikPropsType>,
  ): MacroAdjustmentStep[] => [
      {
        index: 0,
        label: t("macro:adjustmentsPage.wizard.stepOne.label"),
        component: <MacroAdjustmentStepOne />,
        leftBtnText: t("common:actions.cancel"),
        rightBtnText: t("common:actions.next"),
        stepTitle: t("macro:adjustmentsPage.wizard.stepOneTitle"),
      },
      {
        index: 1,
        label: t("macro:adjustmentsPage.wizard.stepTwo.label"),
        component: <MacroAdjustmentStepTwo />,
        leftBtnText: t("common:actions.back"),
        rightBtnText: t("common:actions.next"),
        stepTitle: getStepTitle(1, formikProps) || "",
        stepSubtitle: getStepSubtitle(formikProps),
      },
      {
        index: 2,
        label: t("macro:adjustmentsPage.wizard.stepThree.label"),
        component: <MacroAdjustmentStepThree />,
        leftBtnText: t("common:actions.back"),
        rightBtnText: t("common:actions.next"),
        stepTitle: getStepTitle(2, formikProps) || "",
        stepSubtitle: getStepSubtitle(formikProps),
      },
    ];

  const navigateToAdjustments = () => {
    navigate(MacroToolRoutesConfig.adjustmentsPage.replace(":id", scenarioId), {
      state: {
        headersIndent: headersIndent,
      },
    });
  };

  const handleNavigateToAdjustmentsPage = (
    formikProps: FormikProps<formikPropsType>,
  ) => {
    const showModal = !_.isEqual(formikProps.values, formikProps.initialValues);
    const modalProps = {
      title: t("macro:adjustmentsPage.wizard.lockInModal.title"),
      description: t("macro:adjustmentsPage.wizard.lockInModal.description"),
      actionTitle: t(
        "macro:adjustmentsPage.wizard.lockInModal.backToAdjustments",
      ),
      confirmAction: () => {
        navigateToAdjustments();
        closeModal();
      },
      cancelAction: closeModal,
    };
    showModal
      ? openModal(<ConfirmationModal {...modalProps} />)
      : navigateToAdjustments();
  };

  const formikInitialValues: formikPropsType = {
    name: "",
    description: "",
    inputArea: "",
    selectedPillar: null,
    selectedInputArea: null,
    selectedAdjustmentType: null,
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={macroAdjustmentValidationSchema}
      onSubmit={handleFinish}
    >
      {(formikProps) => {
        return (
          <Form>
            <Box>
              <MacroAdjustmentsWizardHeaderAndStepper
                handleNavigateToAdjustmentsPage={
                  handleNavigateToAdjustmentsPage
                }
                formikProps={formikProps}
                headersIndent={headersIndent}
                activeStep={activeStep}
                steps={steps}
              ></MacroAdjustmentsWizardHeaderAndStepper>

              {/*IMPORTANT: This is the actual step component*/}
              <Box mb={4}>{steps(formikProps)[activeStep].component}</Box>

              <MacroAdjustmentsWizardFooter
                handleLeftButtonClick={handleLeftButtonClick}
                leftButtonDisabled={leftButtonDisabled}
                steps={steps}
                formikProps={formikProps}
                activeStep={activeStep}
                handleRightButtonClick={handleRightButtonClick}
              ></MacroAdjustmentsWizardFooter>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MacroAdjustmentWizard;
