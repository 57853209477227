import axios, { AxiosRequestConfig } from "axios";
import { getAccessToken } from "../../core/http/http-utils";
import { HTTPConstants } from "../../core/http/constants";
import { SupportingPagesRoutesConfig } from "../../features/supporting-pages/navigation/config";

// Create an Axios instance with base configuration
const AXIOS_INSTANCE = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Setup request interceptor to automatically add the token to headers
AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (config.url?.includes(HTTPConstants.refreshTokenURL)) {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      delete config.headers.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Setup response interceptor to handle 401 errors
AXIOS_INSTANCE.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== SupportingPagesRoutesConfig.accessDenied)
        window.location.href = SupportingPagesRoutesConfig.accessDenied;
    }
    return Promise.reject(error);
  },
);

export async function withCustomAxios<T>(
  params?: AxiosRequestConfig,
  extraParams?: AxiosRequestConfig,
): Promise<T> {
  const response = await AXIOS_INSTANCE({ ...params, ...extraParams } || {});
  return response.data;
}
