import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Product, ViewMicroScenario } from "../../../orval/generated/models";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState } from "react";
import { SortableOverlay } from "./SortableOverlay";
import { SortableItem } from "./SortableItem";

type ProductSelectionModalSelectedProductsDnDProps = {
  selectedProducts: Product[];
  changedProducts: Product[];
  setSelectedProducts: (
    selectedProducts: (item: Product[]) => Product[],
  ) => void;
  handleRemoveSelectedProduct: (guid: string) => void;
  handleClearAll: () => void;
  scenarioDetails: ViewMicroScenario;
};

interface ActiveEvent {
  id: number | string;
}

export function ProductSelectionModalSelectedProductsDnD({
  selectedProducts,
  setSelectedProducts,
  handleRemoveSelectedProduct,
  handleClearAll,
}: ProductSelectionModalSelectedProductsDnDProps) {
  const { t } = useTranslation("micro");
  const boxRemHeight = 14;
  const [activeItem, setActiveItem] = useState<Product | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event: { active: ActiveEvent }) {
    const { active } = event;
    const itemIndex = selectedProducts.findIndex(
      ({ custom_display_name, product_name }) =>
        active.id === `${custom_display_name || product_name}`,
    );
    const item = selectedProducts[itemIndex];
    setActiveItem(item);
  }

  function handleDragOver(event: {
    active: ActiveEvent;
    over: ActiveEvent | null;
  }) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setSelectedProducts((products: Product[]) => {
        const oldIndex = products.findIndex(
          ({ custom_display_name, product_name }) =>
            active.id === `${custom_display_name || product_name}`,
        );
        const newIndex = products.findIndex(
          ({ custom_display_name, product_name }) =>
            over.id === `${custom_display_name || product_name}`,
        );

        const newOrder = arrayMove(
          products.map(
            (product) =>
              `${product.custom_display_name || product.product_name}`,
          ),
          oldIndex,
          newIndex,
        );

        return newOrder.map(
          (id) =>
            products.find(
              (product) =>
                `${product.custom_display_name || product.product_name}` === id,
            )!,
        );
      });
    }
  }

  const allProductsLocked = selectedProducts.every((product) => product.guid);

  return (
    <Box>
      <Box
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight="bold">
          {t("productsSection.selectProductModal.selectedProducts")}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={handleClearAll}
          disabled={allProductsLocked}
          sx={{ marginLeft: 1 }}
        >
          {t("productsSection.selectProductModal.clearNewlyAddedProducts")}
        </Button>
      </Box>
      <Box sx={{ height: "32em", overflow: "scroll" }}>
        {selectedProducts.length > 0 ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
          >
            <SortableContext
              items={selectedProducts.map(
                (product) =>
                  `${product.custom_display_name || product.product_name}`,
              )}
              strategy={verticalListSortingStrategy}
            >
              {selectedProducts.map((selectedProduct) => (
                <SortableItem
                  key={`${selectedProduct.custom_display_name || selectedProduct.product_name}`}
                  id={`${selectedProduct.custom_display_name || selectedProduct.product_name}`}
                  item={selectedProduct}
                  selectedProducts={selectedProducts}
                  handleRemoveSelectedProduct={handleRemoveSelectedProduct}
                  overlay={false}
                />
              ))}
            </SortableContext>
            <SortableOverlay>
              {activeItem ? (
                <SortableItem
                  key={`${activeItem.custom_display_name || activeItem.product_name}`}
                  id={`${activeItem.custom_display_name || activeItem.product_name}`}
                  item={activeItem}
                  selectedProducts={selectedProducts}
                  handleRemoveSelectedProduct={handleRemoveSelectedProduct}
                  overlay={true}
                />
              ) : null}
            </SortableOverlay>
          </DndContext>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: `${boxRemHeight}rem`,
            }}
          >
            <Typography>
              {t("productsSection.selectProductModal.noProductSelected")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
