import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { MacroTableWrapper } from "../../../../components/common";
import { MenuItem } from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

type Props = {
  columns: MRT_ColumnDef<any>[];
  data: any;
  onRemoveScenario: Function;
  isMoreThanTwoScenarios: boolean;
};

export const CompareScenarioHeader = ({
  columns,
  data,
  onRemoveScenario,
  isMoreThanTwoScenarios,
}: Props) => {
  const table = useMaterialReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: isMoreThanTwoScenarios,
    enablePagination: false,
    enableBottomToolbar: false,
    renderColumnActionsMenuItems: ({ closeMenu, column }) => {
      return [
        <MenuItem
          key="cancel"
          onClick={() => {
            onRemoveScenario(column.id);
            closeMenu();
          }}
        >
          Remove
          <CloseSharpIcon />
        </MenuItem>,
      ];
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "2px solid #d04a02",
        zIndex: 1,
        "& .Mui-TableHeadCell-Content": {
          justifyContent: "space-between",
        },
      },
    },
    muiTableBodyCellProps: () => ({
      sx: {
        display: "none",
      },
    }),
  });

  return (
    <MacroTableWrapper>
      <MaterialReactTable table={table} />
    </MacroTableWrapper>
  );
};
