import i18next from "i18next";
import { formatNumberOrEmpty } from "../../../../utils/formatNumber/formatNumber";
import {
  SalesVolumeResult,
  ViewMacroScenarioViewModel,
} from "../../../../orval/generated/models";
import { orderWithCDE } from "../../../micro-tool/components/Charts/ChartOrder";
import { ProductConfigurationsMacro } from "../../../micro-tool";

export enum OptionalMetricsTableResultsType {
  BY_PACK_TYPE = "by_pack_type",
  BY_CATEGORY = "by_category",
  BY_PILLAR = "by_pillar",
}

export enum OptionalMetricsTableResultsFilterDataType {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
}

export type OptionalMetricsTableDataItem = {
  [x: string]: string;
  base: string;
  glide: string;
  scenario: string;
};

function getResultsOverviewTableColumns(
  type: OptionalMetricsTableResultsType,
  baseYear: string,
  targetYear: string,
) {
  return [
    {
      accessorKey: type,
      header: i18next.t(`macro:resultsSection.${type}`),
      id: type,
      size: 150,
      maxSize: 150,
      grow: false,
    },
    {
      accessorKey: "base",
      header: `${baseYear} ${i18next.t("macro:resultsSection.baseYear")}`,
      id: "base",
    },
    {
      accessorKey: "glide",
      header: `${targetYear} ${i18next.t("macro:resultsSection.glidePath")}`,
      id: "glide",
    },
    {
      accessorKey: "scenario",
      header: `${targetYear} ${i18next.t("macro:resultsSection.scenario")}`,
      id: "scenario",
    },
  ];
}

function getItemName(item: Record<string, any>): string {
  const firstKey = Object.keys(item)[0];
  return firstKey === "cde"
    ? "CDE"
    : firstKey.charAt(0).toUpperCase() + firstKey.slice(1);
}

function getResultsOverviewTableData(
  type: OptionalMetricsTableResultsType,
  scenario: ViewMacroScenarioViewModel,
  dataType: OptionalMetricsTableResultsFilterDataType,
): OptionalMetricsTableDataItem[] {
  const dataArea = scenario.results?.[type];

  if (!dataArea) {
    return [];
  }

  const filteredDataArea = Object.entries(dataArea).map(([key, value]) => {
    return {
      [key]: value[dataType],
    };
  });

  const tableData: OptionalMetricsTableDataItem[] = filteredDataArea
    .map((item) => {
      const itemValues = Object.values(item)[0];
      if (!itemValues) return null;

      const base = formatNumberOrEmpty(
        type === "by_pillar" ? itemValues.base : itemValues.total?.base,
        dataType,
      );
      const glide = formatNumberOrEmpty(
        type === "by_pillar" ? itemValues.glide : itemValues.total?.glide,
        dataType,
      );
      const scenario = formatNumberOrEmpty(
        type === "by_pillar" ? itemValues.scenario : itemValues.total?.scenario,
        dataType,
      );

      const itemName = getItemName(item);

      return {
        [type]: itemName,
        base,
        glide,
        scenario,
      };
    })
    .filter((item): item is OptionalMetricsTableDataItem => item !== null);

  const totalsData = scenario.results?.by_pillar.total[dataType];
  const totalsObject = totalsData &&
    scenario.results?.by_pillar.total[dataType] && {
      [type]: "Total",
      base: formatNumberOrEmpty(totalsData.base, dataType),
      glide: formatNumberOrEmpty(totalsData.glide, dataType),
      scenario: formatNumberOrEmpty(totalsData.scenario, dataType),
    };

  if (totalsObject && !tableData.some((item) => item[type] === "Total")) {
    tableData.push(totalsObject);
  }

  tableData.sort((a, b) => {
    if (a && a[type] === "Total") return 1;
    if (b && b[type] === "Total") return -1;
    if (type === "by_pillar") {
      return (
        orderWithCDE.findIndex(
          (x) => b && b[type].toLowerCase() === x.toLowerCase(),
        ) -
        orderWithCDE.findIndex(
          (x) => a && a[type].toLowerCase() === x.toLowerCase(),
        )
      );
    } else {
      return a && b ? a[type].localeCompare(b[type]) : 0;
    }
  });

  return tableData;
}

function getResultsOverviewSalesTableData(
  type: OptionalMetricsTableResultsType,
  scenario: ViewMacroScenarioViewModel,
) {
  const salesData =
    scenario.results?.sales_volume?.[type as keyof SalesVolumeResult];
  const totalsData = scenario.results?.sales_volume.total;

  const totalItem: {
    [x: string]: string;
    base: string;
    glide: string;
    scenario: string;
  } = {
    [type]: "Total",
    base: totalsData
      ? formatNumberOrEmpty(totalsData.base, ProductConfigurationsMacro.SALES)
      : "",
    glide: totalsData
      ? formatNumberOrEmpty(totalsData.glide, ProductConfigurationsMacro.SALES)
      : "",
    scenario: totalsData
      ? formatNumberOrEmpty(
          totalsData.scenario,
          ProductConfigurationsMacro.SALES,
        )
      : "",
  };

  const filteredDataArea = salesData
    ? Object.entries(salesData).map(([key, value]) => {
        return {
          [key]: value,
        };
      })
    : [];

  const tableData: OptionalMetricsTableDataItem[] = filteredDataArea
    .map((item) => {
      const itemValues = Object.values(item)[0];
      if (!itemValues) return null;

      const base = formatNumberOrEmpty(
        itemValues.base,
        ProductConfigurationsMacro.SALES,
      );
      const glide = formatNumberOrEmpty(
        itemValues.glide,
        ProductConfigurationsMacro.SALES,
      );
      const scenario = formatNumberOrEmpty(
        itemValues.scenario,
        ProductConfigurationsMacro.SALES,
      );

      const itemName = getItemName(item);

      return {
        [type]: itemName,
        base,
        glide,
        scenario,
      };
    })
    .filter((item): item is OptionalMetricsTableDataItem => item !== null);

  if (tableData && totalItem) {
    tableData.push(totalItem);
  }

  tableData &&
    tableData.sort((a, b) => {
      // Alphabetical with Total always at the end
      if (a[type] === "Total") return 1;
      if (b[type] === "Total") return -1;
      return a[type].localeCompare(b[type]);
    });

  return tableData as OptionalMetricsTableDataItem[];
}

export {
  getResultsOverviewTableColumns,
  getResultsOverviewTableData,
  getResultsOverviewSalesTableData,
};
