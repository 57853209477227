import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import React, { useMemo } from "react";
import {
  CountryViewModel,
  MemberDetails,
  PageMemberDetails,
  type PermissionItem,
} from "../../../../../orval/generated/models";
import { MacroTableWrapper } from "../../../../../components/common";
import { MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generateTableHeight } from "../../../../../assets/styles/helpers/tables";
import { useModal } from "../../../../../components/common/modal";
import { UpsertUserModal } from "../UpsertUserModal";
import { IdamUser, useAuthUser } from "../../../../../core/auth";
import DeleteUserModal from "../DeleteUserModal";

export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

interface UserManagementTableProps {
  data: PageMemberDetails | undefined;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  isLoading: boolean;
}

const UserManagementTable = (props: UserManagementTableProps) => {
  const { t } = useTranslation(["toolConfiguration"]);
  const { openModal } = useModal();
  const userInfo: IdamUser | undefined = useAuthUser();

  const maxPage: number =
    Math.ceil((props.data?.total ?? 1) / props.pagination.pageSize) ?? 1;

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`, // Concatenate first_name and last_name
        header: "Name",
        size: 150,
        subRows: (row: any) => `${row.first_name} ${row.last_name}`,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 150,
      },
      {
        accessorFn: (row) =>
          `${row.permissions.countries.map((country: CountryViewModel) => country.display_name).join(", ")}`, // Access country_access from permissions
        header: "Country Access",
        size: 150,
      },
      {
        accessorFn: (row) => {
          return row.permissions.adjustment_access.map(
            (adjustment: PermissionItem, index: number) => {
              return React.createElement(
                React.Fragment,
                { key: index },
                adjustment.name,
                React.createElement("br"),
              );
            },
          );
        },
        header: "Adjustment Access",
        size: 150,
      },
      {
        accessorFn: (row) => {
          return row.permissions.functional_access.map(
            (adjustment: PermissionItem, index: number) => {
              return React.createElement(
                React.Fragment,
                { key: index },
                adjustment.name,
                React.createElement("br"),
              );
            },
          );
        },
        header: "Functional Access",
        size: 150,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: props.data?.items ?? [],
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableExpandAll: false, //disable expand all button
    enableRowActions: true,
    muiTableContainerProps: {
      sx: {
        height: generateTableHeight("269px"),
      },
    },
    enableStickyHeader: true,
    paginationDisplayMode: "pages",
    onPaginationChange: props.setPagination,
    state: { pagination: props.pagination },
    rowCount: props.data?.size ?? 4,
    autoResetPageIndex: false,
    manualPagination: true,
    pageCount: maxPage,
    muiPaginationProps: {
      color: "primary",
      rowsPerPageOptions: [4, 20, 100],
      shape: "rounded",
      variant: "outlined",
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    initialState: {
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        boxShadow: "none !important",
        border: "none !important",
      },
    },
    muiTableBodyProps: {
      sx: {
        maxHeight: "20vh",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "1px solid #6A6A6A",
        borderRight: "none !important",
        fontWeight: "500",
      },
    },
    muiTableBodyCellProps: ({ row }) => {
      return {
        sx: {
          borderBottom: "1px solid #CBCBCB",
          borderRight: "none !important",
          verticalAlign: "top",
          borderTop: "none !important",
          maxWidth: "50px",
        },
      };
    },
    localization: {
      actions: "",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        <MenuItem
          key={0}
          onClick={() => {
            handleOpenEditUserModal(row.original);
            closeMenu();
          }}
          sx={{ m: 0, minWidth: "150px" }}
          disabled={
            userInfo === undefined ||
            userInfo!.preferredMail === row.original.email ||
            userInfo!.email === row.original.email
          }
        >
          {t("common:actions.edit")}
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            handleOpenDeleteUserModal(row.original);
            closeMenu();
          }}
          sx={{ m: 0, minWidth: "150px" }}
          disabled={
            userInfo === undefined ||
            userInfo!.preferredMail === row.original.email ||
            userInfo!.email === row.original.email
          }
        >
          {t("common:actions.delete")}
        </MenuItem>,
      ];
    },
  });

  const handleOpenEditUserModal = (userInformation: MemberDetails) => {
    openModal(
      <UpsertUserModal isEditing={true} userInformation={userInformation} />,
    );
  };

  const handleOpenDeleteUserModal = (userInformation: MemberDetails) => {
    openModal(<DeleteUserModal userInformation={userInformation} />);
  };

  return (
    <MacroTableWrapper>
      {props.data?.total ? (
        <MaterialReactTable table={table} />
      ) : !props.isLoading ? (
        <Typography>{t("userManagementPage.noResultsMessage")}</Typography>
      ) : null}
    </MacroTableWrapper>
  );
};
export default UserManagementTable;
