import Box from "@mui/material/Box";
import { ResponsiveMarimekko } from "@nivo/marimekko";
import { MekkoChartData } from "./MekkoHelperService";
import { formatNumberOrEmpty } from "../../../../utils/formatNumber/formatNumber";
import { ProductConfigurationsMacro } from "../../../micro-tool";
import { useTranslation } from "react-i18next";

type MekkoChartProps = {
  chartData: MekkoChartData;
  chartColours: string[];
};

// Utility function to calculate the darkness of a color considering opacity
function getDarkness(color: string): number {
  const rgba = color.match(/\d+(\.\d+)?/g)?.map(Number);
  if (!rgba) return 0;
  const [r, g, b, a = 1] = rgba;
  // Calculate the luminance and adjust for opacity
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance * a + (1 - a); // Blend with white based on opacity
}

export function MekkoChart({ chartData, chartColours }: MekkoChartProps) {
  function formatId(id: string) {
    id = id.charAt(0).toUpperCase() + id.slice(1);
    return id.replaceAll("_", " ");
  }

  const { t } = useTranslation("macro");

  return (
    <Box width="100%" height={550}>
      <ResponsiveMarimekko
        data={chartData.data}
        id="pillar"
        value="weighting"
        dimensions={chartData.dimensions}
        innerPadding={0}
        borderWidth={2}
        borderColor={"#fff"}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          legend: t("mekkoAnalysis.yAxisLabel"),
          legendOffset: -60,
          legendPosition: "middle",
        }}
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        colors={chartColours}
        enableGridX={false}
        enableGridY={false}
        enableLabels
        tooltip={({ bar }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "10px",
                border: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: `${bar.color}`,
                }}
              ></div>
              <div>
                {formatId(bar.id)}:
                {formatNumberOrEmpty(
                  bar.value as number,
                  ProductConfigurationsMacro.MEKKO,
                )}
                %
              </div>
            </div>
          );
        }}
        layers={[
          "grid",
          "axes",
          "bars",
          "legends",
          ({ bars }) => {
            return (
              <>
                {bars.map(
                  (bar) =>
                    bar.height && (
                      <text
                        key={bar.key}
                        x={bar.x + bar.width / 2}
                        y={bar.y + bar.height / 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill={getDarkness(bar.color) < 0.7 ? "#fff" : "#000"}
                        style={{
                          fontSize: "12px",
                          ...((bar.datum.data.weighting as number) < 10
                            ? { writingMode: "vertical-lr" }
                            : {}),
                          ...(bar.height < 40 ? { visibility: "hidden" } : {}),
                          ...(bar.height < 160 &&
                          (bar.datum.data.weighting as number) < 10
                            ? { visibility: "hidden" }
                            : {}),
                        }}
                      >
                        {formatId(bar.id)}{" "}
                        {`(${formatNumberOrEmpty(bar.value as number, ProductConfigurationsMacro.MEKKO)}%)`}
                      </text>
                    ),
                )}
              </>
            );
          },
        ]}
      />
    </Box>
  );
}
